import React from "react"

import Categories from "./categories"
import LinkedItems from "./linkitems"
import SearchResultsLayout from "./searchresultslayout"
import QuizCTA from "./quizcta"

import { DefaultSearchContainer, SearchTitle } from "./search.styles"

/**
 *
 * @param {[]} categories
 * @param {[]} faq
 * @param {Function} onClick
 * @param {string} title
 * @param {{}} skin
 * @returns {React.JSX.Element}
 * @constructor
 */
const DefaultSearch = ({ categories, title, faq, onClick }) => {
  return (
    <DefaultSearchContainer>
      <SearchResultsLayout isReversed={true}>
        <div>
          <SearchTitle text={title} />
          <Categories categories={categories} />
          <QuizCTA />
        </div>
        <>
          {Boolean(faq?.length) ? (
            <LinkedItems
              title={"Frequently Asked Questions"}
              items={[...faq]}
              onClick={onClick}
            />
          ) : null}
        </>
      </SearchResultsLayout>
    </DefaultSearchContainer>
  )
}

export default DefaultSearch
