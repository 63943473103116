import { useEffect, useState } from "react"

import {
  SEGMENT_NAMES,
  SEGMENT_PRIORITY,
} from "../components/account/rewards/consts"

//NT2-20279 Loyalty | Global Ribbon for myNutrafol Customers
export const useRewards = (userState) => {
  const [campaigns, setCampaigns] = useState(null)

  useEffect(() => {
    setCampaigns(userState.customerCampaigns)
  }, [userState])

  let active = null
  let accepted = null
  let nextReward = null

  if (userState.isLoggedIn && Array.isArray(campaigns)) {
    active = campaigns
      ?.filter((c) => !c.is_accepted)
      .sort((a, b) => {
        // Compare expiration days first (earliest first)
        if (a.remaining_lifetime < b.remaining_lifetime) return -1
        if (a.remaining_lifetime > b.remaining_lifetime) return 1

        // If the expiration days are the same, sort by the defined priority order
        return (
          SEGMENT_PRIORITY.indexOf(a.segment_name) -
          SEGMENT_PRIORITY.indexOf(b.segment_name)
        )
      })
    accepted = campaigns?.filter((c) => c.is_accepted)
    //In the event that there are multiple rewards  default to the most relevant one
    if (active.length > 0) {
      nextReward = active[0]
    } else if (accepted.length > 0) {
      //A banner to refer a friend shows up as soon as a reward (all rewards) have been activated
      nextReward = { segment_name: SEGMENT_NAMES.REFER_A_FRIEND }
    }
  }

  return { campaigns, active, accepted, nextReward }
}
