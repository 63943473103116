import React, { useEffect, useState } from "react"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import styled from "@emotion/styled"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
/*
 * AdChoicesLink is our own implementation of [evidon-notice-link]
 * since we using 4 types of footer it is easier to have our own link and use window.evidon.notice API to show a popup
 * https://community.crownpeak.com/t5/Headless-Consent-CaaS/The-Consent-Platform-UCP-JavaScript-API-Documentation/ta-p/7647
 */

const AdChoices = styled.div``
const ERROR = `No Active Domain has been loaded for AdChoices. It is ok for local environment. 
If you are using server with process.env.GATSBY_EVIDON_ID=1, pls try again or contact support.`

const handleEvidon = (e, el) => {
  e?.preventDefault()
  if (window.evidon?.notice?.activeDomain) {
    try {
      segmentEvent("Modal Viewed", {
        path: window?.location?.pathname,
        name: "AdChoices - Do Not Sell or Share",
      })
      window.evidon.notice.dropPixel(15)
      window.evidon.notice.showOptions(null)
    } catch (error) {
      console.error(
        "AdChoices error --->>> ",
        window.evidon.notice.activeDomain,
        error
      )
    }
  } else {
    console.warn(ERROR)
  }
}

const AdChoicesLink = () => {
  const [apiLoaded, setApiLoaded] = useState(false)

  // we are waiting until window.evidon.notice API been loaded

  useEffect(() => {
    let evidonInterval,
      cnt = 0
    if (process.env.GATSBY_EVIDON_ID) {
      evidonInterval = setInterval(() => {
        if (cnt === 60) {
          clearInterval(evidonInterval)
          console.error(ERROR)
        }
        if (window.evidon?.notice?.activeDomain) {
          setApiLoaded(true)
          console.log(
            "AdChoicesLink activeDomain found: ",
            window.evidon?.notice?.activeDomain
          )
          clearInterval(evidonInterval)
        } else {
          if (cnt % 2 === 0) {
            // console.log("AdChoicesLink activeDomain waiting...", cnt / 2)
          }
          cnt++
        }
      }, 500)
    }

    return () => {
      return evidonInterval && clearInterval(evidonInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return apiLoaded ? (
    <AdChoices
      className={process.env.GATSBY_RENDER_EVIDON === "1" ? "block" : "hidden"}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href={`#`}
        onClick={(e) => {
          handleEvidon(e)
        }}
        aria-label={`AdChoices - Do Not Sell Or Share (Opens in a popup)`}
      >
        <TextElement
          element="span"
          className="body--regular text-master-base-black inline-block"
          text={`AdChoices - Do Not Sell Or Share`}
        />
      </a>
    </AdChoices>
  ) : null
}

export default AdChoicesLink
