import { useState, useEffect } from "react"
import useIsMobileResize from "./useIsMobileResize"

export const useIsScrollTopBottom = () => {
  const { isMobile } = useIsMobileResize()
  const [isTop, setIsTop] = useState(true)
  const [isBottom, setIsBottom] = useState(false)

  const onScrollHandler = (el) => {
    const scrollTop = el.scrollTop
    const scrollHeight = el.scrollHeight
    const clientHeight = el.clientHeight

    setIsTop(scrollTop === 0)
    setIsBottom(clientHeight === scrollHeight - scrollTop)
  }

  useEffect(() => {
    if (isMobile) {
      onScrollHandler()
    }
  }, [isMobile])

  return { isTop, isBottom, onScrollHandler }
}
