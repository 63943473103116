import React from "react"

const formatDate = (val) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  }
  return new Date(val).toLocaleString("default", options)
}

const getFormattedTextFromNode = (node) => {
  switch (node?.marks?.[0]?.type) {
    case "bold":
      return `<span class='font-grilliBook font-bold'>${node.value}</span>`
    case "underline":
      return `<span class='underline'>${node.value}</span>`
    case "italic":
      return `<em>${node.value}</em>`
    default:
      return `${node.value}`
  }
}

const getFormattedTextFromNodeDinamo = (node) => {
  switch (node?.marks?.[0]?.type) {
    case "bold":
      return `<span class='font-dinamoBold'>${node.value}</span>`
    case "underline":
      return `<span class='underline'>${node.value}</span>`
    case "italic":
      return `<em>${node.value}</em>`
    default:
      return `${node.value}`
  }
}

const renderRichText = (nodes) => {
  let HTML = ""
  const RAW = typeof nodes === "string" ? JSON.parse(nodes) : nodes
  const NODES = RAW?.content?.[0]?.content
  if (NODES && NODES.length > 0) {
    NODES.forEach((node) => {
      HTML += getFormattedTextFromNode(node)
    })
  }
  return HTML
}

const renderRichTextDinamo = (nodes) => {
  let HTML = ""
  const RAW = typeof nodes === "string" ? JSON.parse(nodes) : nodes
  const NODES = RAW?.content?.[0]?.content
  if (NODES && NODES.length > 0) {
    NODES.forEach((node) => {
      HTML += getFormattedTextFromNodeDinamo(node)
    })
  }
  return HTML
}

export { formatDate, renderRichText, renderRichTextDinamo }
