import React, { useState, useEffect, useRef, useContext } from "react"
import { globalHistory } from "@reach/router"
import { Link } from "gatsby"
import classNames from "classnames"
import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import popupHelper from "@nutrafol/popup-helper"
import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import { data, accordionNavigationData } from "./data"

import AccordionNavigation from "./accordionnavigation"
import Logo from "./logo"
import HeaderIcons from "./headericons"
import AccountIcon from "./accounticon"
import Ribbon from "./ribbon"
import HeaderAtc from "../atcoptions/header-atc"
import HeaderAtcCms from "../../scalp/atcoptions/header-atc"
import LowerWrap from "./headerlowerwrap"
import ArrowIcon from "../../../../images/arrow-left-black.png"
import UserContext from "../../../../context/UserContext"
import { isProductPath } from "../../../../utils/general"
import SiteSwitch from "./siteswitch"
import NavSwitch from "./navswitch"
import MobileNavFlex from "./mobilenavflex"
import Search from "../../../search/search"
import { SearchContext } from "../../../search/searchcontext"

import {
  AboveHeaderWrap,
  SiteSwitchBox,
  HeaderWrap,
  HeaderContainer,
  ContentsContainer,
  BelowFoldContainer,
  MobileAlt,
  NavVar1,
  tabletFold,
} from "./header.styled"

const ctaText = "Take the Quiz"
const NovaHeader = (props) => {
  const isMouseInHeader = useRef(false)
  const [navIsOpen, setNavIsOpen] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [withRibbon, setWithRibbon] = useState(props.withRibbon)
  const [headerRibbonData, setHeaderRibbonData] = useState()

  const userState = useContext(UserContext)
  const { isSearchVisible, closeSearchDropdown } = useContext(SearchContext)
  const { location } = props
  const pathname = props.location && props.location.pathname
  const PROMO_VARIANT = props.PROMO_VARIANT
  const contentfulHeaderRibbon = props.contentfulHeaderRibbon
  const isGwpLp = /travel-kit-offer/.test(props.location.pathname) || false
  let isAccountPage = false
  let isQuizLp = false
  let showCart = true
  let isFHK = false

  if (pathname) {
    showCart = pathname.indexOf("/checkout") === -1
    isAccountPage = pathname.indexOf("account/") > -1
    isQuizLp = pathname.indexOf("hair-wellness-quiz-intro") > -1
    isFHK = pathname === "/hair-kit/"
  }

  useEffect(() => {
    if (isFHK) {
      setHeaderRibbonData(null)
    } else {
      setHeaderRibbonData(contentfulHeaderRibbon)
    }
  }, [contentfulHeaderRibbon, isFHK])

  useEffect(() => {
    setWithRibbon(props.withRibbon)
  }, [pathname, props.withRibbon])

  // Reset search on route change
  useEffect(() => {
    return globalHistory.listen(() => {
      closeSearchDropdown(false)
    })
  }, [closeSearchDropdown])

  const isZeLoaded = () => !!(window.zE && typeof window.zE.show === "function")
  const isAdaLoaded = () => !!window.adaEmbed
  const isAttentiveLoaded = () => !!window.attentive_overlay

  const toggleMobileNav = (isOpen = false) => {
    segmentEvent("Hamburger Navigation Menu Clicked", {
      module_location: "navigation",
      path: "/",
    })

    if (isAttentiveLoaded()) {
      try {
        window.attentive_overlay.style.zIndex = isOpen ? "10" : "2147483647"
      } catch (error) {
        console.error("attentive_overlay  zIndex failed ", error)
      }
    }
    if (!isOpen) {
      setNavIsOpen(false)
      // toggleScroll(true)
      setMenuIsOpen(false)
      if (isZeLoaded()) {
        window.zE.show()
      } else if (isAdaLoaded()) {
        const adaBtn = document.getElementById("ada-button-frame")
        if (adaBtn) {
          adaBtn.style.display = "block"
        }
      }
    } else {
      setNavIsOpen(true)
      // toggleScroll(false)
      if (isAdaLoaded()) {
        const adaBtn = document.getElementById("ada-button-frame")
        if (adaBtn) {
          adaBtn.style.display = "none"
        }
      } else if (isZeLoaded()) {
        window.zE.hide()
      } else {
        // if menu is tapped within 3 seconds of page load - wait for zE and hide it
        let zeInterval = setInterval(() => {
          if (isZeLoaded()) {
            window.zE.hide()
            clearInterval(zeInterval)
          }
        }, 250)
      }
    }
  }

  /*
   * Commenting out all toggleScroll references. This was causing bugs when navigating between pages with different headers and with opening/closing the search modal
   * const toggleScroll = (exists = false, searchScrollPosition) => {
   *   const bodyEl =
   *     typeof window !== "undefined" ? document.querySelector("body") : null
   */

  /*
   *   const htmlEl =
   *     typeof window !== "undefined" ? document.querySelector("html") : null
   */

  /*
   *   // Todo: Refactor scroll position for scroll / mobile bug
   *   if (typeof document !== "undefined" && bodyEl) {
   *     if (exists === true) {
   *       htmlEl.style.removeProperty("height")
   *       bodyEl.style.removeProperty("overflow")
   *       bodyEl.style.removeProperty("position")
   *       bodyEl.style.removeProperty("top")
   *       bodyEl.style.removeProperty("width")
   *       window.scrollTo(0, searchScrollPosition || scrollPosition)
   *       // document.body.removeEventListener('touchmove', props.mobileHandler, options)
   *     } else {
   *       const scrolledOffset = window.scrollY
   *       setScrollPosition(scrolledOffset)
   *       htmlEl.style.height = "100vh"
   *       bodyEl.style.overflow = "hidden"
   *       bodyEl.style.position = "fixed"
   *       // bodyEl.style.top = `-${scrolledOffset}px`
   *       bodyEl.style.width = "100%"
   *       // document.body.addEventListener('touchmove', props.mobileHandler, options)
   *     }
   *   }
   * }
   */

  if (typeof window !== "undefined") {
    // close menu on resize > 768
    const handleResize = () => {
      const navEl = document.querySelector(".nav-open")
      if (navEl && window.innerWidth >= tabletFold) {
        toggleMobileNav(false)
      }
    }
    window.addEventListener("resize", handleResize)
  }

  const handleNavLogoExitClick = () => {
    // toggleScroll(true)
    toggleMobileNav(false)
    userState.setAtcData(null)
    userState.setActiveOption(0)
    segmentEvent("Navigation Clicked", {
      module_location: "navigation",
      destination: "/",
      type: "link",
    })
  }

  const [isPdp, setIsPdp] = useState(false)

  useEffect(() => {
    let adaChat = document?.querySelector("#ada-entry")
    const [banner] = document?.getElementsByClassName("video-banner-wrap")
    const video = banner?.getElementsByTagName("video")[0]

    /**
     * Sometimes 3rd party are loaded later,
     * might be a good idea to have a `GlobalContext`
     */
    if (!adaChat) {
      const intervalId = setInterval(() => {
        adaChat = document?.querySelector("#ada-entry")
        if (adaChat && isSearchVisible) {
          adaChat?.classList?.add("hidden")
          clearInterval(intervalId)
        }
      }, 0)
    }

    const bodyEl =
      typeof window !== "undefined" ? document.querySelector("body") : null

    if (isSearchVisible) {
      adaChat?.classList?.add("hidden")
      video?.pause()
      // toggleScroll(false)

      bodyEl.style.overflow = "hidden"

      return
    }

    if (!isSearchVisible) {
      bodyEl.style.removeProperty("overflow")
    }

    adaChat?.classList?.remove("hidden")
    if (!navIsOpen) {
      // toggleScroll(true, searchScrollPosition)
    }
    video?.play()
  }, [isSearchVisible])

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsPdp(isProductPath(window.location.pathname))
    }
  }, [userState.atcData])

  useEffect(() => {
    let rect = { y: 0 }
    let gap = popupHelper.isMobile() ? 40 : 80

    const handleScroll = () => {
      // all pages are setting .pagescrolled class
      const val = document.documentElement.scrollTop
      const classList = document.body.classList
      const pageScrolled = classList.contains("pagescrolled")
      const pageScrolledAtc = classList.contains("stickyatc")

      if (!isMouseInHeader.current) {
        if (val > 85) {
          if (!pageScrolled) {
            classList.add("pagescrolled")
          }
        } else {
          if (pageScrolled) {
            classList.remove("pagescrolled")
          }
        }
      }
      // PDP pages are using setAtcData() via context and setting .stickyatc class
      if (isProductPath(window.location.pathname)) {
        document.querySelectorAll(".pdp-main-atc").forEach((ela) => {
          if (ela && ela.offsetParent && ela.offsetTop) {
            rect = ela.getBoundingClientRect()
          }
        })
        if (rect.y - gap < 0) {
          if (!pageScrolledAtc) {
            classList.add("stickyatc")
          }
        } else {
          if (pageScrolledAtc) {
            classList.remove("stickyatc")
          }
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isPdp])

  const scrollToView = () => {
    const atcLabel = document.querySelector("#atc-label")
    if (atcLabel) {
      return window.scrollTo({
        top: atcLabel?.offsetTop + 48,
        behavior: "smooth",
      })
    } else {
      document.querySelectorAll(".pdp-main-atc").forEach((ela, idx) => {
        ela &&
          ela.offsetParent &&
          ela.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          })
      })
    }
  }

  const onClick = () => {
    segmentEvent("Marketing CTA Clicked", {
      destination: "/quiz/",
      module_location: "navigation",
      text: ctaText,
      type: "link",
    })
    setTimeout(() => {
      window.location = process.env.GATSBY_MAGENTO_URL + "quiz/"
    }, 500)
  }

  const ReturnShop = styled(Box)`
    display: none;
    @media (min-width: 768px) {
      display: block;
      margin-top: auto;
      margin-left: 30px;
    }
    > a {
      width: fit-content;
      display: flex;
      flex-direction: row;
      > img {
        width: 13px;
        height: 11px;
        margin-top: auto;
        margin-bottom: 7px;
      }
      border-bottom: 1px solid #22202f;
      position: relative;
    }
  `

  const TakqQuizAB = () => {
    if (userState.isLoggedIn) {
      return <AccountIcon />
    }
    return <ButtonWild textLink label={ctaText} onClick={onClick} />
  }

  return (
    <UserContext.Consumer>
      {(state) => (
        <HeaderWrap
          isLoggedIn={userState.isLoggedIn}
          data-mousedown
          className={classNames("header-wrap", {
            "nav-open": navIsOpen,
            "has-ribbon": withRibbon,
          })}
          onMouseEnter={() => (isMouseInHeader.current = true)}
          onMouseLeave={() => (isMouseInHeader.current = false)}
          onTouchEnd={() =>
            setTimeout(() => {
              isMouseInHeader.current = false
            })
          }
          onClick={(e) => {
            // Prevents closing nav when header is clicked
            if (isSearchVisible) {
              e.stopPropagation()
            }
            isMouseInHeader.current = false
          }}
        >
          {!isAccountPage ? (
            withRibbon && headerRibbonData ? (
              <AboveHeaderWrap
                flexWrap="wrap"
                className={`sm:mb-[4px] ribbon-container`}
              >
                <SiteSwitchBox
                  width={[1, 1, 225]}
                  mr={["4px"]}
                  className={isGwpLp ? "!hidden" : ""}
                >
                  <SiteSwitch />
                </SiteSwitchBox>
                <Box
                  width={[0, 0, "calc(100% - 229px)"]}
                  className="show-md-up"
                >
                  <Ribbon
                    contentfulHeaderRibbon={contentfulHeaderRibbon}
                    isMouseInHeader={isMouseInHeader}
                    PROMO_VARIANT={PROMO_VARIANT}
                    location={location}
                  />
                </Box>
              </AboveHeaderWrap>
            ) : (
              <Box
                className={`no-ribbon-switch mb-[4px] ${
                  isGwpLp ? "!hidden" : ""
                }`}
              >
                <SiteSwitchBox width={[1]}>
                  <SiteSwitch />
                </SiteSwitchBox>
              </Box>
            )
          ) : null}
          <HeaderContainer className="container container-master">
            <div className="stickyatcheader">
              <BelowFoldContainer>
                <MobileAlt onClick={scrollToView}>
                  <TextElement
                    element="span"
                    text={"Select Your Product"}
                    className="body--large text-master-base-default"
                  />
                </MobileAlt>
                <div className="show-md-up">
                  <Flex
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box className="">
                      <Logo handleNavLogoExitClick={handleNavLogoExitClick} />
                    </Box>
                    <Box className="">
                      {isPdp ? (
                        <HeaderAtc state={state} />
                      ) : (
                        <HeaderAtcCms state={state} />
                      )}
                    </Box>
                  </Flex>
                </div>
              </BelowFoldContainer>
            </div>
            <ContentsContainer className="nonstickyheader wrap">
              {isAccountPage ? (
                <Flex
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent={"space-between"}
                  height={[56, 56, 72, 80]}
                >
                  <Box width={[0.3]} className="left-wrap-account show-md-up">
                    <ReturnShop>
                      <Link to={"/"}>
                        <img src={ArrowIcon} alt="" role="presentation" />
                        <TextElement
                          element="p"
                          text={"Shop"}
                          className="body--large text-master-base-black ml-1"
                        />
                      </Link>
                    </ReturnShop>
                  </Box>
                  <Box
                    width={["fit-content", null, 4 / 10]}
                    ml={[16, null, "unset"]}
                    className="center-wrap-account"
                  >
                    <Logo
                      handleNavLogoExitClick={handleNavLogoExitClick}
                      isAccountPage={isAccountPage}
                    />
                  </Box>
                  <Box
                    width={[null, null, 3 / 10]}
                    className="right-wrap-account"
                  >
                    <Flex
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <HeaderIcons
                        navIsOpen={navIsOpen}
                        showCart={showCart}
                        isProductPage={isPdp}
                        isAccountPage={isAccountPage}
                        isQuizLp={isQuizLp}
                        toggleMobileNav={toggleMobileNav}
                        isIconVisibleOnRightSideOnly
                        location={location}
                      />
                    </Flex>
                  </Box>
                </Flex>
              ) : (
                <>
                  <div className="mobile-nav-flex">
                    <NavVar1
                      flexWrap="wrap"
                      alignItems="center"
                      width={[1]}
                      className={classNames({
                        "!border-b-0": true,
                      })}
                    >
                      <NavSwitch type="Men" copy="Nutrafol Men" />
                      <NavSwitch
                        type="Skin"
                        copy="Nutrafol Skin"
                        external={true}
                        isGwpLp={isGwpLp}
                      />
                      <Box ml={"auto"} pr={[16]} className="takequiz">
                        <TakqQuizAB />
                      </Box>
                    </NavVar1>
                  </div>
                  <MobileNavFlex
                    data={data}
                    isAccountPage={isAccountPage}
                    navIsOpen={navIsOpen}
                    isPdp={isPdp}
                    isQuizLp={isQuizLp}
                    handleNavLogoExitClick={handleNavLogoExitClick}
                    showCart={showCart}
                    isMouseInHeader={isMouseInHeader}
                    toggleMobileNav={toggleMobileNav}
                    isIconVisibleOnRightSideOnly
                  />
                  {withRibbon && headerRibbonData ? (
                    <div className={`hide-md-up ribbon-container`}>
                      <Ribbon
                        contentfulHeaderRibbon={contentfulHeaderRibbon}
                        isMouseInHeader={isMouseInHeader}
                        PROMO_VARIANT={PROMO_VARIANT}
                        location={location}
                      />
                    </div>
                  ) : null}
                </>
              )}
            </ContentsContainer>
            {userState.isLoggedIn ? (
              <LowerWrap
                navIsOpen={navIsOpen}
                showCart={showCart}
                // isAccountPage={isAccountPage || isSupportPage}
                isAccountPage={isAccountPage}
                toggleMobileNav={() => toggleMobileNav(!navIsOpen)}
                userName={userState?.customer?.firstname}
                textStyle={{ color: theme.wild.color.nova.base.black }}
              />
            ) : null}
          </HeaderContainer>
          {navIsOpen ? (
            <AccordionNavigation
              data={accordionNavigationData}
              toggleNav={setNavIsOpen}
              navIsOpen={navIsOpen}
              menuIsOpen={menuIsOpen}
            />
          ) : null}
          {isSearchVisible ? <Search /> : null}
        </HeaderWrap>
      )}
    </UserContext.Consumer>
  )
}

export default NovaHeader
