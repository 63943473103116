export const ScreenReader = {
  /*
   * srSpeak(text, priority)
   * text: the message to be vocalised
   * priority (non mandatory): "polite" (by default) or "assertive" 
   */

  speak: (text, priority) => {
    const el = document.createElement("div")
    const id = "speak-" + Date.now()
    el.setAttribute("id", id)
    el.setAttribute("aria-live", priority || "polite")
    el.classList.add("aria-invisible")
    document.body.appendChild(el)
  
    window.setTimeout(function () {
      document.getElementById(id).innerHTML = text
    }, 100)
  
    window.setTimeout(function () {
      document.body.removeChild(document.getElementById(id))
    }, 3000)
  }
}