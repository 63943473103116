import React, { useState, useContext } from "react"
import { navigate, Link } from "gatsby"
import styled from "@emotion/styled"
import classNames from "classnames"
import { Box, Flex } from "@rebass/grid/emotion"

import UserContext from "../../../../context/UserContext"

import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import DownArrow from "../../../../assets/icons/master/down-arrow.svg"
import AccountSVGGreen from "../../../../assets/icons/master/user-icon-green.svg"

import BottomNavigationMobile from "./bottomnavigationmobile"

const Nav = styled.nav`
  display: none;
  position: fixed;
  background: ${theme.color.white};
  width: 100%;
  height: calc(100% - 96px);
  left: 0;
  bottom: 0;
  padding: 0;
  z-index: 12;
  overflow-y: scroll;
  top: 96px;
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 0.2);

  @media (min-width: 768px) {
    box-shadow: 0px 11px 15px 0px rgba(0, 87, 82, 0.08);
    box-shadow: 0px 9px 46px 0px rgba(0, 87, 82, 0.06);
    box-shadow: 0px 24px 38px 0px rgba(0, 87, 82, 0.06);
    left: 8px;
    width: calc(100% - 16px);
    top: 128px;
    height: calc(100% - 135px);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  @media (min-width: 1024px) {
    display: none;
    position: static;
    width: auto;
    height: auto;
    padding: 0;
  }

  p.title--large {
    font-size: 20px;
    line-height: 1.2;
    @media (min-width: 360px) {
      font-size: 24px;
    }
  }
`

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  ul {
    padding-top: ${theme.spacing.small};
    height: 100%;
  }

  &.navIsOpen {
    animation: closeSubMenu 0.25s;
    -moz-animation: closeSubMenu 0.25s;
    -webkit-animation: closeSubMenu 0.25s;
  }

  &.menuIsOpen {
    left: -100%;
    animation: openSubMenu 0.25s;
    -moz-animation: openSubMenu 0.25s;
    -webkit-animation: openSubMenu 0.25s;
  }
`

const Products = styled(Box)`
  background-color: #ECF1F5;
  button:not(.textLink) {
    padding: 13px 6px;
    width: 100%;
  }
`

const AccordionButton = styled.button`
  display: block;
  width: 100%;
  padding: 16px;

  svg {
    transition: transform ease-in-out 0.15s;
  }

  &.active svg {
    transform: rotate(180deg);
  }
`

const AccordionBody = styled(Box)`
  .products {
    background: ${theme.wild.color.master.primary.alt};
    padding-top: 16px;
    padding-bottom: 16px;
  }
  button {
    width: 100%;
  }
`

const MenuButton = styled.button`
  display: block;
  width: 100%;
  padding: 16px;
  text-align: left;
`

const FixedBottomNav = styled(Flex)`
  position: fixed;
  background: ${theme.color.white};
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow:
    0px -10px 15px 0px rgba(0, 0, 0, 0.04),
    0px -6px 10px 0px rgba(0, 0, 0, 0.02),
    0px -2px 4px 0px rgba(0, 0, 0, 0.04);

  @media (min-width: 768px) {
    left: 8px;
    right: 8px;
  }

  button {
    width: 100%;
    padding: 13px 0 !important;
    height: 48px !important;

    @media (max-width: 360px) {
      font-size: 12px !important;
    }
  }
`

// Accordion Component
const Accordion = ({ title, open = false, children }) => {
  const [isOpen, setIsOpen] = useState(open)

  const toggleAccordion = () => {
    if (!isOpen) {
      segmentEvent("Navigation Expanded", {
        module_location: "navigation",
        text: title,
      })
    }
    setIsOpen(!isOpen)
  }

  return (
    <Box>
      <AccordionButton
        className={isOpen ? "active" : ""}
        onClick={toggleAccordion}
      >
        <Flex justifyContent="space-between" alignItems="center" px={8}>
          <Box>
            <TextElement
              text={title}
              className="title--large text-master-base-black"
            />
          </Box>
          <Box>
            <DownArrow />
          </Box>
        </Flex>
      </AccordionButton>
      {isOpen ? <Box mb={16}>{children}</Box> : null}
    </Box>
  )
}

const AccordionNavigation = ({
  data,
  toggleNav,
  navIsOpen,
  menuIsOpen,
}) => {
  const userState = useContext(UserContext)

  const trackClicked = (text, destination, segmentName) => {
    segmentEvent(segmentName, {
      module_location: "navigation",
      type: "button",
      text,
      destination,
    })
  }

  const handleLinkClick = (
    text,
    destination,
    donav = false,
    segmentName = "Navigation Clicked"
  ) => {
    setTimeout(toggleNav(false), 50)
    trackClicked(text, destination, segmentName)
    if (destination !== "#" && donav) {
      navigate(destination)
    }
  }

  const handleQuizClick = (label) => {
    segmentEvent("Marketing CTA Clicked", {
      destination: "/quiz",
      module_location: "navigation",
      text: label,
      type: "button",
    })
    setTimeout(() => {
      window.location = process.env.GATSBY_MAGENTO_URL + "quiz/"
    }, 500)
  }

  const generateAccountButton = (isLoggedIn) => {
    const dest = isLoggedIn ? "/account/" : "/customer/account/login/"
    const label = isLoggedIn ? "My Account" : "Log In"

    return (
      <ButtonWild
        secondary
        onClick={() =>
          handleLinkClick(label, dest, true, "Marketing CTA Clicked")
        }
      >
        <Flex alignItems="center">
          <Box width={16} height={16} mr={8}>
            <AccountSVGGreen width="100%" height="100%" />
          </Box>
          <Box>{label}</Box>
        </Flex>
      </ButtonWild>
    )
  }

  return (
    <Nav className="mobile-navigation">
      <MenuWrapper
        className={classNames("menu-wrapper", {
          menuIsOpen: menuIsOpen,
          navIsOpen: navIsOpen,
        })}
      >
        {data.map((section, index) => (
          <div key={section.name}>
            {section.type === "hgn" ? (
              <Products py={16} m={16}>
                <Box px={16}>
                  <TextElement
                    text={section.title}
                    className="title--large text-master-base-black mb-4"
                  />
                </Box>
                <Box>
                  <Box className={section.name}>
                    {section.children.map((subitem, idx) => (
                      <Box
                        key={`${section.name}-${subitem.title}`}
                        mb={section.children.length !== idx + 1 ? 16 : 0}
                      >
                        {subitem.type === "link" ? (
                          <Link
                            onClick={() =>
                              handleLinkClick(subitem.title, subitem.url)
                            }
                            to={subitem.url}
                            aria-label={`Open ${subitem.name} page`}
                            id={`SubItemLink-${index}-${idx}`}
                            className="block px-[24px]"
                          >
                            <TextElement
                              type="span"
                              text={subitem.title}
                              className={`${
                                section.name === "products"
                                  ? "body--xlarge-b"
                                  : "body--large-b"
                              } ${
                                !subitem?.isMens
                                  ? "text-master-primary-secondary"
                                  : "text-master-secondary-secondary"
                              }`}
                            />
                            {subitem.subtitle ? (
                              <TextElement
                                type="span"
                                text={subitem.subtitle}
                                className={`body--small ${
                                  section.name === "products"
                                    ? "text-master-base-black"
                                    : "text-master-base-alt-alt"
                                }`}
                              />
                            ) : null}
                          </Link>
                        ) : null}
                        {subitem.type === "button" ? (
                          <Box className="px-[24px]">
                            <ButtonWild
                              secondary
                              label={subitem.label}
                              onClick={() => handleQuizClick(subitem.label)}
                            />
                          </Box>
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Products>
            ) : null}
            {section.type === "accordion" ? (
              <Accordion title={section.title} open={section.open}>
                <AccordionBody px={16}>
                  <Box className={section.name}>
                    {section.children.map((subitem, idx) => (
                      <Box
                        key={`${section.name}-${subitem.title}`}
                        mb={section.children.length !== idx + 1 ? 16 : 0}
                      >
                        {subitem.type === "link" ? (
                          <Link
                            className="px-[16px] block"
                            onClick={() =>
                              handleLinkClick(subitem.title, subitem.url)
                            }
                            to={subitem.url}
                            aria-label={`Open ${subitem.name} page`}
                            id={`SubItemLink-${index}-${idx}`}
                          >
                            <TextElement
                              type="span"
                              text={subitem.title}
                              className={`${
                                section.name === "products"
                                  ? "body--xlarge-b"
                                  : "body--large-b"
                              } ${
                                !subitem?.isMens
                                  ? "text-master-primary-secondary"
                                  : "text-master-secondary-secondary"
                              }`}
                            />
                            {subitem.subtitle ? (
                              <TextElement
                                type="span"
                                text={subitem.subtitle}
                                className={`body--small ${
                                  section.name === "products"
                                    ? "text-master-base-black"
                                    : "text-master-base-alt-alt"
                                }`}
                              />
                            ) : null}
                          </Link>
                        ) : null}
                        {subitem.type === "button" ? (
                          <ButtonWild
                            secondary
                            label={subitem.label}
                            onClick={() => handleQuizClick(subitem.label)}
                          />
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                </AccordionBody>
              </Accordion>
            ) : null}
            {section.type === "link" ? (
              <MenuButton
                onClick={() =>
                  handleLinkClick(section.title, section.url, true)
                }
              >
                <TextElement
                  type="span"
                  text={section.title}
                  className="title--large text-master-base-black px-2"
                />
              </MenuButton>
            ) : null}
          </div>
        ))}
        <Box pt={16} pb={72}>
          <BottomNavigationMobile toggleNav={toggleNav} />
        </Box>
      </MenuWrapper>
      <FixedBottomNav height={64} px={16} py={8}>
        <Box width={1 / 2} pr="4px">
          {generateAccountButton(userState.isLoggedIn)}
        </Box>
        <Box width={1 / 2} pl="4px">
          <ButtonWild
            secondary
            arrowRight
            label="Shop All Products"
            onClick={() =>
              handleLinkClick("Shop All Products", "/products/", true)
            }
          />
        </Box>
      </FixedBottomNav>
    </Nav>
  )
}

export default AccordionNavigation
