import React, { useState, useEffect, createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import algoliasearch from "algoliasearch"
import algoliarecommend from "@algolia/recommend"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

export const SearchContext = createContext(null)

const ALGOLIA_APP_ID = process.env.GATSBY_ALGOLIA_APP_ID
const ALGOLIA_API_TOKEN = process.env.GATSBY_ALGOLIA_API_KEY

const resultSearchDefault = {
  product: false,
  article: false,
}

const allProductsQuery = graphql`
  {
    allContentfulProduct {
      nodes {
        title
        subTitle
        sku
        searchImage {
          url
        }
      }
    }
  }
`

export const SearchContextProvider = ({ children }) => {
  const [initialLoad, setInitialLoad] = useState(true)
  const [searchClient, setSearchClient] = useState(null)
  const [recommendClient, setRecommendClient] = useState(null)
  const [zendeskSearchIndex, setZendeskSearchIndex] = useState(null)
  const [contentfulProducts, setContentfulProducts] = useState([])
  const [isSearchVisible, setIsSearchVisible] = useState(false)
  const [search, setSearch] = useState("")
  const [searchState, setSearchState] = useState(null)
  const [defaultFAQs, setDefaultFAQs] = useState([])
  const [defaultFAQsLoading, setDefaultFAQsLoading] = useState(true)
  const [subscriptionsAndOrders, setSubscriptionsAndOrders] = useState({})
  const [noResultsFound, setNoResultsFound] = useState(false)
  const [searchScrollPosition, setSearchScrollPosition] = useState(0)

  const { allContentfulProduct } = useStaticQuery(allProductsQuery)

  useEffect(() => {
    setContentfulProducts(allContentfulProduct)
  }, [allContentfulProduct])

  useEffect(() => {
    // Algolia Search
    const search_client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_TOKEN)
    setSearchClient(search_client)
    setZendeskSearchIndex(search_client.initIndex("Zendesk_production"))

    // Algolia Recommend
    const recommend_client = algoliarecommend(ALGOLIA_APP_ID, ALGOLIA_API_TOKEN)
    setRecommendClient(recommend_client)

    // Get all contentful products
  }, [])

  useEffect(() => {
    const getDefaultFAQs = async () => {
      if (zendeskSearchIndex) {
        try {
          const relatedFAQs = await zendeskSearchIndex.getObjects([
            "28789023418259",
            "29416479957395",
            "29416668240915",
          ])
          setDefaultFAQs(relatedFAQs?.results)
        } catch (error) {
          console.error(`Algolia API error related articles ${error}`)
        } finally {
          setDefaultFAQsLoading(false)
        }
      }
    }
    getDefaultFAQs()
  }, [zendeskSearchIndex])

  const [resultSearchVisible, setResultSearchVisible] =
    useState(resultSearchDefault)

  const openSearchDropdown = () => {
    setSearchScrollPosition(window.scrollY)
    // Commenting this out because it was causing the page to scroll to the top on mobile when the search modal was opened
    // if (window.matchMedia("(max-width: 767px)").matches) {
    //   window.scrollTo(0, 0)
    // }

    setIsSearchVisible(true)

    if (searchState) {
      segmentEvent("Search Modal Opened", {
        name: "Search",
        location: "navigation",
        state: searchState,
      })
    }
  }

  // Trigger first modal open once search state is avail
  useEffect(() => {
    if (searchState && initialLoad) {
      segmentEvent("Search Modal Opened", {
        name: "Search",
        location: "navigation",
        state: searchState,
      })
      setInitialLoad(false)
    }
  }, [searchState, initialLoad])

  const closeSearchDropdown = (runSegmentEvent = true) => {
    setIsSearchVisible(false)
    setResultSearchVisible(resultSearchDefault)
    setSearch("")
    if (runSegmentEvent) {
      segmentEvent("Search Modal Closed", {
        name: "Search",
        location: "navigation",
        state: searchState,
      })
    }
  }

  const toggleSearchDropdown = () => {
    if (!isSearchVisible) {
      openSearchDropdown()
    } else {
      closeSearchDropdown()
    }
  }

  const value = {
    searchClient,
    recommendClient,
    zendeskSearchIndex,
    contentfulProducts,
    isSearchVisible,
    setIsSearchVisible,
    search,
    setSearch,
    searchState,
    setSearchState,
    resultSearchVisible,
    setResultSearchVisible,
    defaultFAQs,
    defaultFAQsLoading,
    subscriptionsAndOrders,
    setSubscriptionsAndOrders,
    noResultsFound,
    setNoResultsFound,
    searchScrollPosition,
    setSearchScrollPosition,
    openSearchDropdown,
    closeSearchDropdown,
    toggleSearchDropdown,
  }

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}
