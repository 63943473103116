import { useState, useEffect } from 'react'
import { debounce } from 'lodash'

const useIsMobileResize = (w = 767) => {
  const getIsMobile = () =>
    typeof window !== "undefined" && window.innerWidth <= w
  const [isMobile, setIsMobile] = useState(getIsMobile())

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile())
    }

    window.addEventListener("resize", debounce(onResize, 100))

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return isMobile
}

export default useIsMobileResize