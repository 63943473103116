import React from "react"

import { ResultsContent } from "./search.styles"

const SearchResultsLayout = ({
  isReversed = false,
  children: [content, sidebar],
}) => (
  <ResultsContent isReversed={isReversed}>
    <main className={sidebar ? "max-w-[800px]" : ""}>{content}</main>
    <aside>{sidebar}</aside>
  </ResultsContent>
)

export default SearchResultsLayout
