import React from "react"
import { navigate } from "gatsby"

import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"

import { SearchTitle } from "./search.styles"

import ProductList from "./productlist"
import LinkedItems from "./linkitems"
import SearchResultsLayout from "./searchresultslayout"
import QuizCTA from "./quizcta"

/**
 *
 * @param {string} text
 * @param {[]} faq
 * @param {[]} category
 * @param {Function} onClick
 * @param {[]} suggestedHits
 * @returns {Element}
 * @constructor
 */
const NoResults = ({ text, faq, pages, category, onClick }) => {
  return (
    <div className={"w-full h-full"}>
      <div className={"no-results mb-8"}>
        <TextElement
          className="body--xlarge mb-1"
          text={`No results found for "${text}".`}
        />
        <TextElement
          className="body--large no-results-subcopy"
          text="Try another search or select an option below."
        />
      </div>
      <SearchResultsLayout>
        <>
          <SearchTitle text="Popular Products" />
          <div className="mt-1 sm:mt-2 md:mt-4">
            <ProductList
              products={category
                .sort((a, b) => a.orderQty - b.orderQty)
                .slice(0, 4)}
            />
          </div>
          <QuizCTA />
        </>
        <>
          <div className="mb-8">
            {Boolean(faq.length) ? (
              <LinkedItems
                title={"Frequently Asked Questions"}
                items={[...faq]}
                onClick={onClick}
              />
            ) : null}
          </div>
          <div>
            {Boolean(pages?.length) ? (
              <LinkedItems
                title={"Resources"}
                items={[...pages]}
                onClick={(e, item) => navigate(item.path)}
              />
            ) : null}
          </div>
        </>
      </SearchResultsLayout>
    </div>
  )
}

export default NoResults
