import React, { memo } from "react"
import styled from "@emotion/styled"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"

const LoaderScreen = styled.div`
  position: fixed;
  z-index: 10;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  &.contained {
    position: absolute;
  }
`
const Loader = styled.div``

const LoadingAnimation = memo(({ contained, isMiniCart, isLoggingIn }) => {
  const LoaderWrap = styled.div`
    display: block;
    position: absolute;
    top: ${isMiniCart ? "calc(50% - 16px)" : "calc(40% + 25px)"};
    left: 50%;
    width: ${isMiniCart ? "165px" : "165px"};
    transform: translateX(-50%);
    @media (min-width: 768px) {
      top: ${isMiniCart ? "calc(50% - 16px)" : "50%"};
    }
  `

  return (
    <LoaderScreen className={contained ? "contained" : ""}>
      <LoaderWrap className="loader-wrap">
        <Loader></Loader>
        <Loader></Loader>
        <Loader></Loader>
        <Loader></Loader>
        <Loader></Loader>
        {isLoggingIn ? (
          <TextElement
            text="Logging you in"
            element="p"
            className="body--large text-master-base-black text-center"
          />
        ) : null}
      </LoaderWrap>
    </LoaderScreen>
  )
})

export default LoadingAnimation
