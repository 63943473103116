import React  from "react"
import {  navigate } from "gatsby"
import styled from "@emotion/styled"
import { Box } from "@rebass/grid/emotion"

import { TextElementMen } from "@nutrafol/nutrafol-ui-kit/dist/TextElementMen"
import ArrowIcon from "../../../../assets/icons/wild/arrow-right-green.svg"
import { data } from "../../../../data/men/men-concierge"

const NavigationShopBannerBox = styled(Box)`
  cursor: pointer;
  height: 125px;
  background-color: #c1c1c1;
  background-image: url("https://images.ctfassets.net/0rbfqd9c4jdo/33eC43FZozmVjZaSJ8c6wX/b6480f81218c10501820cb074d9bca29/growthplanplus-menu-banner-mobile-men-gradient.jpg?fl=progressive&h=125");
  background-size: auto;
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 8px;
  @media (min-width: 560px) {
    background-position-x: right;
  }
`

const ButtonWrapperBanner = styled.div`
  position: absolute;
  left: 16px;
  top: 8px;
  width: 80%;
  max-width: 226px;
  .paragraph {
    max-width: 198px;
  }
  @media (max-width: 374px) {
    left: 8px;
    top: 12px;
    h4 {
      //font-size: 24px !important;
      //line-height: 22px !important;
    }
    .paragraph {
      max-width: 171px;
    }
  }

  .btn-green {
    color: #00857d !important;
    font-weight: bold;
  }

  button {
    display: flex;
    flex-direction: row;
    svg {
      transform: translate(4px, 3px);
    }
  }
`

const NavigationShopBannerMobile = ({trackClicked, className}) => {

  return (
    <NavigationShopBannerBox className={className} tabIndex="0" onClick={() => {
      trackClicked(data.touchPoints.label,  data.touchPoints.link)
      navigate( data.touchPoints.link)
    }}>
      <div>
        <ButtonWrapperBanner>
          <TextElementMen
            element="h4"
            text={data.touchPoints.title}
            className="title--medium text-master-base-default"
          />
          <Box mt={['4px','4px',8]} mb={['4px','4px', 8]}>
            <TextElementMen
              element="p"
              text={data.touchPoints.subtitle}
              className="body--small paragraph text-master-base-default"
            />
          </Box>

          <button>
            <TextElementMen
              element="p"
              text={data.touchPoints.label}
              className="body--small btn-green text-master-primary-default"
            />
            <ArrowIcon/>
          </button>

        </ButtonWrapperBanner>
      </div>

    </NavigationShopBannerBox>
  )

}

export default NavigationShopBannerMobile