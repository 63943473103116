import React from "react"
import styled from "@emotion/styled"
import Arrow from "../../../../assets/icons/wild/arrow-up-right.svg"

const SiteSwitchLink = styled.a`
  justify-content: center;

  font-size: 14px !important;
  line-height: 40px !important;

  svg {
    display: inline;
    transform: translate(0, -2px);
  }

  @media (max-width: 767px) {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 4.93%
    );
  }
  @media (min-width: 768px) {
    background: #fff;
    position: static;
    //width: calc(100% - 4px);
    width: 100%;
    box-shadow:
      0px 11px 15px 0px rgba(0, 56, 87, 0.08),
      0px 9px 46px 0px rgba(0, 56, 87, 0.06),
      0px 24px 38px 0px rgba(0, 56, 87, 0.06);
  }
`

const SiteSwitch = (props) => {
  return (
    <SiteSwitchLink
      href={`${process.env.GATSBY_MAGENTO_URL}skin/`}
      className="px-md block text-center items-center rounded-lg"
    >
      <span className="font-dinamo text-skin-default-black">
        {`Switch to Nutrafol Skin `}
      </span>
      <Arrow />
    </SiteSwitchLink>
  )
}

export default SiteSwitch
