import { PRODUCT_IMAGES } from "../../utils/imagesMap"

export const comparableItems = [
  {
    heading: "Women",
    img: PRODUCT_IMAGES.WOM1.label4,
    imgsmall: PRODUCT_IMAGES.WOM1.label3,
    text: "For women 18-44 who are experiencing signs of hair thinning, this physician-formulated hair growth supplement uses natural ingredients to target root causes of thinning such as stress, lifestyle, and nutrition.",
    buttonText: "Shop Now",
    link: "/nutrafol-core-for-women/",
    buttonLink: "/nutrafol-core-for-women/",

    comparison: {
      comparisonColumns: [
        "Recommended Age Range",
        "If you are Experiencing",
        "Differences",
      ],
      name: "Women's",
      comparisonItemsArray: ["Women 18-44", 0, 1],
      comparisonItems: [
        "18-35",
        "Lörem ipsum didade savasamma ell. Fadina gigana. Fadina gigana. ",
        "Lörem ipsum didade savasamma ell. Fadina gigana. Fadina gigana. ",
      ],
    },
    options: [
      {
        title: "Monthly Delivery",
        savings: "Save 10%",
        text_description:
          "A 1-month supply each month + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "WOM1-S01",
      },
      {
        title: "3-Month Delivery",
        savings: "Save 15%",
        text_description:
          "A 3-month supply delivered every 3 months + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "WOM3-S01",
      },
      {
        title: "6-Month Delivery",
        savings: "Save 20%",
        text_description:
          "A 6-month supply with refills delivered every 6 months + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "WOM6-S01",
      },
      {
        title: "Single Bottle",
        text_description: "A 1-month supply delivered once.",
        shippingText:
          "<span class='font-dinamoBold'>$88 + $6.95 shipping.</span>",
        sku: "WOM1-N01",
      },
    ],
  },
  {
    heading: "Women's Vegan",
    img: PRODUCT_IMAGES.VEG1.label4,
    imgsmall: PRODUCT_IMAGES.VEG1.label3,
    text: "For women 18-44 with a plant-based lifestyle who are experiencing signs of hair thinning, this physician-formulated hair growth supplement uses natural, 100% vegan ingredients to target root causes of thinning such as stress, nutritional gaps, and gut health.",
    buttonText: "Shop Now",
    link: "/womens-vegan/",
    buttonLink: "/womens-vegan/",
    className: "vegan",
    badge: "New",
    comparison: {
      comparisonColumns: [
        "Recommended Age Range",
        "If you are Experiencing",
        "Differences",
      ],
      name: "Women's Vegan",
      comparisonItemsArray: ["Women 18-44", "Plant-based lifestyle", 1],
      comparisonItems: [
        "18-35",
        "Lörem ipsum didade savasamma ell. Fadina gigana. Fadina gigana. ",
        "Lörem ipsum didade savasamma ell. Fadina gigana. Fadina gigana. ",
      ],
    },
    options: [
      {
        title: "Monthly Delivery",
        savings: "Save 10%",
        text_description:
          "A 1-month supply each month + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "VEG1-S01",
      },
      {
        title: "3-Month Delivery",
        savings: "Save 15%",
        text_description:
          "A 3-month supply delivered every 3 months + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "VEG3-S01",
      },
      {
        title: "6-Month Delivery",
        savings: "Save 20%",
        text_description:
          "A 6-month supply with refills delivered every 6 months + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "VEG6-S01",
      },
      {
        title: "Single Bottle",
        text_description: "A 1-month supply delivered once.",
        shippingText:
          "<span class='font-dinamoBold'>$88 + $6.95 shipping.</span>",
        sku: "VEG1-N01",
      },
    ],
  },
  {
    heading: "Women's Balance",
    img: PRODUCT_IMAGES.BAL1.label4,
    imgsmall: PRODUCT_IMAGES.BAL1.label3,
    text: "For women 45+ experiencing hair thinning due to aging, this physician-formulated hair growth supplement uses natural ingredients to target root causes of thinning such as hormonal shifts and metabolism.",
    buttonText: "Shop Now",
    link: "/women-balance/",
    buttonLink: "/women-balance/",
    comparison: {
      comparisonColumns: [
        "Recommended Age Range",
        "If you are Experiencing",
        "Differences",
      ],
      name: "Women's Balance",
      comparisonItemsArray: [
        "Women 45+",
        "Hormonal changes, such as menopause",
        1,
      ],
      comparisonItems: [
        "35-99",
        "Lörem ipsum didade savasamma ell. Fadina gigana. Fadina gigana. ",
        "Lörem ipsum didade savasamma ell. Fadina gigana. Fadina gigana. ",
      ],
    },
    options: [
      {
        title: "Monthly Delivery",
        savings: "Save 10%",
        text_description:
          "A 1-month supply each month + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "BAL1-S01",
      },
      {
        title: "3-Month Delivery",
        savings: "Save 15%",
        text_description:
          "A 3-month supply delivered every 3 months + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "BAL3-S01",
      },
      {
        title: "6-Month Delivery",
        savings: "Save 20%",
        text_description:
          "A 6-month supply with refills delivered every 6 months + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "BAL6-S01",
      },
      {
        title: "Single Bottle",
        text_description: "A 1-month supply delivered once.",
        shippingText:
          "<span class='font-dinamoBold'>$88 + $6.95 shipping.</span>",
        sku: "BAL1-N01",
      },
    ],
  },
  {
    heading: "Postpartum",
    img: PRODUCT_IMAGES.POS1.label4,
    imgsmall: PRODUCT_IMAGES.POS1.label3,
    text: "For women in the first year post-birth experiencing signs of hair thinning and shedding, this OBGYN-developed hair growth supplement supports whole-body recovery by targeting root causes of postpartum thinning such as hormones, stress, and nutrition with natural, breastfeeding-friendly ingredients.",
    buttonText: "Shop Now",
    link: "/nutrafol-postpartum-for-women/",
    buttonLink: "/nutrafol-postpartum-for-women/",
    comparison: {
      comparisonColumns: [
        "Recommended Age Range",
        "If you are Experiencing",
        "Differences",
      ],
      name: "Women's Postpartum",
      comparisonItemsArray: [
        "Women in the first year post-childbirth",
        "Postpartum recovery",
        1,
      ],
      comparisonItems: [
        "18-45",
        "Lörem ipsum didade savasamma ell. Fadina gigana. Fadina gigana. ",
        "Lörem ipsum didade savasamma ell. Fadina gigana. Fadina gigana. ",
      ],
    },
    options: [
      {
        title: "Monthly Delivery",
        savings: "Save 10%",
        text_description:
          "A 1-month supply each month + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "POS1-S01",
      },
      {
        title: "3-Month Delivery",
        savings: "Save 15%",
        text_description:
          "A 3-month supply delivered every 3 months + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "POS3-S01",
      },
      {
        title: "6-Month Delivery",
        savings: "Save 20%",
        text_description:
          "A 6-month supply with refills delivered every 6 months + free naturopathic doctor consults.",
        shippingText: "Free Shipping. Pause or cancel anytime.",
        sku: "POS6-S01",
      },
      {
        title: "Single Bottle",
        text_description: "A 1-month supply delivered once.",
        shippingText:
          "<span class='font-dinamoBold'>$88 + $6.95 shipping.</span>",
        sku: "POS1-N01",
      },
    ],
  },
]

export const columns = [
  {
    text: "For who",
    rows: [
      "Women 18-44",
      "Women 18-44",
      "Women 45+",
      "Women in the first year post-childbirth",
    ],
  },
  {
    text: "Optimized for",
    rows: [
      0,
      "Plant-based lifestyle",
      "Hormonal changes, such as menopause",
      "Postpartum recovery",
    ],
  },
  {
    text: "Clinically tested",
    rows: [1, 1, 1, 1],
  },
]
