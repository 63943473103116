import React, { useContext } from "react"
import { Link } from "gatsby"

import { Products, ProductWrapper } from "./search.styles"
import ResultCard from "./resultcard"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import { SearchContext } from "./searchcontext"

import { setCookie } from "../../utils/cookies"

const ProductList = ({ products }) => {
  const { searchState } = useContext(SearchContext)

  return (
    <Products>
      {products.map((product, index) => (
        <ProductWrapper
          className={`h-auto ${
            index === products.length - 1 ? "mr-4 sm:mr-0" : ""
          }`}
          key={`${product.sku}-${index}`}
        >
          <Link
            to={product?.slug || product?.urlPath}
            onClick={() => {
              segmentEvent("Search Clicked", {
                destination: product?.urlPath,
                name: "Search",
                location: "navigation",
                state: searchState,
                text: product.title,
                type: "Link",
                // Algolia Properties
                index: "Products",
                eventType: "click",
                queryID: product?.queryID,
                objectID: product?.objectID,
              })
              setCookie("queryID", product?.queryID)
            }}
          >
            <ResultCard key={product.sku} data={product} />
          </Link>
        </ProductWrapper>
      ))}
    </Products>
  )
}

export default ProductList
