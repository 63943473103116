export const PDP_URLS = [
  "/nutrafol-core-for-women/",
  "/womens-vegan/",
  "/women-balance/",
  "/nutrafol-postpartum-for-women/",
  "/men/products/thinning-hair-growth-supplement/",
  "/men/products/hairline-thinning-dht-blocker-supplement/", // duo
  "/men/products/thinning-hair-supplements-and-serum/", // duo
  "/de-stress-hair-growth-duo/", // booster duo
  "/gut-microbiome-hair-growth-duo/", // booster duo
  "/strengthening-hair-growth-duo/", // booster duo
  "/hormone-support-hair-growth-duo/", // booster duo
  "/toxin-cleanse-hair-growth-duo/", // booster duo
  "/energy-hair-growth-duo/", // booster duo
  "/scalp/cleanser-shampoo/", // scalp
  "/scalp/products/conditioner/", // scalp
  "/scalp/hair-serum/", // scalp
  "/scalp/exfoliator-mask/", // scalp
  "/scalp/essence/", // scalp
]
