import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from "apollo-boost"
import fetch from "isomorphic-fetch"
import { readCookie } from "../utils/cookies"
import { getUrlParameter } from "../utils/general"

const httpLink = new HttpLink({
  uri: `${process.env.GATSBY_MAGENTO_URL}graphql`,
})
const searchLink = new HttpLink({
  uri: `${process.env.GATSBY_GRAPHQL_SEARCH}`,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  let customHeaders = operation.getContext()?.headers
    ? operation.getContext().headers
    : {}

  let gatsbytoken =
    getUrlParameter("token") !== ""
      ? getUrlParameter("token")
      : readCookie("gatsbytoken")

  // add the bearer token and any other headers passed
  operation.setContext({
    headers: {
      ...customHeaders,
      authorization: `Bearer ${gatsbytoken}`,
    },
  })
  return forward(operation)
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.split(
    (operation) => operation.getContext()?.clientName === "searchLink",
    authMiddleware.concat(searchLink),
    authMiddleware.concat(httpLink)
  ),
  fetch,
})

/*
 * export const client = new ApolloClient({
 *   uri: `${process.env.GATSBY_MAGENTO_URL}graphql`,
 *   request: operation => {
 *     operation.setContext({
 *       headers: {
 *         Authorization: readCookie('gatsbytoken')
 *           ? `Bearer ${readCookie('gatsbytoken')}`
 *           : undefined,
 *       },
 *     })
 *   },
 *   fetch
 * })
 */
