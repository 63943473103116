import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

export const handleNavigationSegmentEvent = ({
  text = "",
  destination = "",
}) => {
  segmentEvent("Navigation Clicked", {
    module_location: "navigation",
    text,
    destination,
  })
}
