import React, { useEffect, useState, useContext } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"

import CartIcon from "../../../../assets/icons/wild/cart-icon-white.svg"
import CartContext from "../../../../context/CartContext"
import { handleNavigationSegmentEvent } from "./utils/segmentEvents"

const envUrl = process.env.GATSBY_MAGENTO_URL

const MiniCartWrapper = styled.div`
  padding: 7px;
  position: relative;
`
const CartIconHolder = styled.div`
  svg {
    width: 20px;
    height: 21px;
  }
  ${queries.medium`
    svg {
      width: 19px;
      height: 20px;
    }
  `}
`

const CartQty = styled.span`
  color: ${theme.wild.color.master.base.default};
  background: ${theme.wild.color.men.primary.default};
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 3px 0;
  &.hide {
    display: none;
  }
`

const HeaderCartIcon = () => {
  const [cartNum, setCartNum] = useState(0)
  const [cartGender, setCartGender] = useState("")

  const cartState = useContext(CartContext)

  useEffect(() => {
    const getCartTotalItems = (items) => {
      let totalItems = 0
      items &&
        Object.keys(items).forEach((key) => {
          totalItems += items[key].quote.qty
        })
      setCartNum(totalItems)
    }

    const getCart = () => {
      cartState.setIsCartFetching(true)
      fetch(`${envUrl}rest/V1/nutrafol-carts/`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (typeof resJson !== "object") {
            const cartJson = JSON.parse(resJson)
            cartState.setCart(cartJson)
            getCartTotalItems(cartJson.items)
            if (typeof cartJson === "object") {
              let gender = ""
              if (cartJson.items !== []) {
                if (Object.keys(cartJson.items).length > 0) {
                  Object.keys(cartJson.items).forEach((item) => {
                    let product = cartJson.items[item]
                    if (product?.product_gender === "Women") {
                      gender = "women"
                    }
                  })
                }
              }
              setCartGender(gender)
            }
          }
        })
        .catch((error) => {
          console.warn("Fetch error: ", error)
        })
        .finally(() => cartState.setIsCartFetching(false))
    }

    getCart()
  }, [setCartNum, setCartGender])

  return (
    <MiniCartWrapper>
      <Link
        to={`/checkout/cart`}
        className="icon-holder icon-cart"
        id="cart-total-wrap"
        onClick={() =>
          handleNavigationSegmentEvent({
            destination: "/checkout/cart/",
          })
        }
      >
        <CartIconHolder>
          <CartIcon />
        </CartIconHolder>
        <CartQty className={`num-holder ${cartNum < 1 ? "hide" : ""}`}>
          <span id="cart-total-items">{cartNum}</span>
        </CartQty>
      </Link>
      <input type="hidden" id="cart-gender" value={cartGender} />
    </MiniCartWrapper>
  )
}

export default HeaderCartIcon
