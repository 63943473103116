import React from "react"
import { navigate } from "gatsby"

import styled from "@emotion/styled"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"

import { columns, comparableItems } from "./comparisondata"

import Minus from "../../assets/icons/wild/minus-icon-dark.svg"
import Check from "../../assets/icons/wild/icon-check-wild-dark.svg"

const RowHeader = styled.div`
  border-top: 1px solid ${theme.wild.color.master.base.alt.secondary};
  border-bottom: 1px solid ${theme.wild.color.master.base.alt.secondary};
  text-align: center;
  background: ${theme.wild.color.master.primary.alt};
  padding: 4px;

  flex-basis: 100%;
  vertical-align: center;

  font-size: 12px;
  line-height: 1.5;
  font-family: ${theme.wild.font.dinamo.bold};
`

const Cell = styled.div`
  vertical-align: middle;
  height: 112px;
  width: calc(25% - 4px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
`

const ComparisonTable = () => (
  <>
    <TextElement
      text="An overview of our formulas:"
      element="p"
      className="title--small"
    />
    <div className={"bg-[#f8f8f5] py-6 px-2"}>
      <div className={"flex"}>
        {comparableItems.map((item) => (
          <div className={"text-center"} key={item.heading}>
            <img src={item.imgsmall} alt={item.heading} />
            <ButtonWild
              textLink
              label={item.heading}
              onClick={() => navigate(item.link)}
            />
          </div>
        ))}
      </div>

      {columns.map((column, id) => (
        <div className={"flex flex-wrap gap-[5px] mt-3"} key={columns[id].text}>
          <RowHeader className={"text-master-primary-default"}>
            {columns[id].text}
          </RowHeader>
          {column.rows.map((row, id) => (
            <Cell key={`${row}-${id}`}>
              {typeof row !== "number" ? (
                <TextElement
                  text={row}
                  className={"text-master-base-black body--regular"}
                  element={"p"}
                />
              ) : Boolean(row) ? (
                <Check />
              ) : (
                <Minus />
              )}
            </Cell>
          ))}
        </div>
      ))}
      <div className={"flex gap-2 mt-2"}>
        {comparableItems.map((item) => (
          <div className={"w-1/4 "}>
            <ButtonWild
              primary
              label={item.buttonText}
              className={"w-full"}
              onClick={() => navigate(item.buttonLink)}
            />
          </div>
        ))}
      </div>
    </div>
  </>
)

export default ComparisonTable
