import styled from "@emotion/styled"

const SpinLoaderContainer = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: center;
  align-items: center;
  height: calc(100% - 200px);

  .spinner {
    height: 65px;
    width: 65px;
    border-radius: 50%;
    border: 5px solid transparent;
    animation: loadingSpinner 1s linear infinite;

    background: linear-gradient(white, white),
      conic-gradient(from 0.15turn, white, #22202f);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
`

const SpinLoader = () => (
  <SpinLoaderContainer>
    <div className={"spinner"} />
  </SpinLoaderContainer>
)

export default SpinLoader
