import React, { useState } from "react"

const defaultState = {
  isMobileNavOpen: false,
  selectedProduct: {},
  availableProducts: [],
}

const NavContext = React.createContext(defaultState)

const NavProvider = props => {
  const { children } = props

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [isPageScrolled, setIsPageScrolled] = useState(false)
  const [showPdpAtc, setShowPdpAtc] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState()
  const [availableProducts, setAvailableProducts] = useState()

  return (
    <NavContext.Provider
      value={{
        isMobileNavOpen,
        setIsMobileNavOpen: bool => {
          document.body.classList.toggle("no-scroll", bool)
          setIsMobileNavOpen(bool)
        },
        isPageScrolled,
        setIsPageScrolled: bool => {
          document.body.classList.toggle("pagescrolled", bool)
          setIsPageScrolled(bool)
        },
        showPdpAtc,
        setShowPdpAtc: bool => {
          setShowPdpAtc(bool)
        },
        selectedProduct,
        setSelectedProduct: productObj => {
          setSelectedProduct(productObj)
        },
        availableProducts,
        setAvailableProducts: optionsArr => {
          setAvailableProducts(optionsArr)
        },
      }}
    >
      {children}
    </NavContext.Provider>
  )
}

export default NavContext

export { NavProvider }
