import gql from "graphql-tag"

export const getCustomerOrdersAndSubscriptions = gql`
  query GetCustomerOrdersAndSubscriptions {
    customer {
      orders(pageSize: 250) {
        items {
          number
          order_date
          status
          shipping_method
          concierge_parent_subscription_id
          is_concierge
          shipments {
            id
            number
            tracking {
              carrier
              number
              title
            }
          }
          billing_address {
            firstname
            lastname
          }
          shipping_address {
            city
            company
            country_code
            firstname
            lastname
            middlename
            postcode
            prefix
            region
            region_id
            street
            telephone
          }
          payment_methods {
            additional_data {
              name
              value
            }
            name
            type
          }
          items {
            id
            image
            label1
            is_core
            product_name
            product_category
            core_category
            product_sku
            quantity_ordered
            product_sale_price {
              value
              currency
            }
            billing_interval
            applied_sale_rules {
              rule_id
              name
              description
              is_gift
            }
          }
          total {
            base_grand_total {
              currency
              value
            }
            discounts {
              amount {
                currency
                value
              }
              label
            }
            grand_total {
              currency
              value
            }
            shipping_handling {
              amount_excluding_tax {
                currency
                value
              }
              amount_including_tax {
                currency
                value
              }
              discounts {
                amount {
                  currency
                  value
                }
              }
              taxes {
                amount {
                  currency
                  value
                }
                rate
                title
              }
              total_amount {
                currency
                value
              }
            }
            subtotal {
              currency
              value
            }
            taxes {
              amount {
                currency
                value
              }
              rate
              title
            }
            total_giftcard {
              currency
              value
            }
            total_shipping {
              currency
              value
            }
            total_tax {
              currency
              value
            }
          }
        }
      }
    }
    subscriptions {
      loyalty_campaigns {
        segment_name
        booster_accepted_list
        run_with_next_rebill
      }
      entity_id
      concierge_parent_subscription_id
      is_concierge
      custom_discount
      increment_id
      quote_id
      description
      customer_id
      created_at
      next_run
      last_run
      subtotal
      length
      status
      frequency_count
      frequency_unit
      product_growth_plan_data
      journey_start_date
      compliance_message
      is_california
      reactivate_compliance_message
      quiz_result {
        token
        created_at
      }
      logs {
        order_increment_id
      }
      additional {
        key
        value
      }
      quote {
        entity_id
        customer_email
        subtotal
        grand_total
        items {
          item_id
          sku
          name
          description
          product_id
          qty
          price
          row_total
        }
      }
    }
  }
`

export const getCustomerOrder = gql`
  query GetCustomerOrder($order_id: String) {
    customer {
      orders(filter: { number: { eq: $order_id } }) {
        items {
          number
          order_date
          status
          shipping_method
          concierge_parent_subscription_id
          is_concierge
          shipments {
            id
            number
            tracking {
              carrier
              number
              title
            }
          }
          billing_address {
            firstname
            lastname
          }
          shipping_address {
            city
            company
            country_code
            firstname
            lastname
            middlename
            postcode
            prefix
            region
            region_id
            street
            telephone
          }
          payment_methods {
            additional_data {
              name
              value
            }
            name
            type
          }
          items {
            id
            image
            label1
            is_core
            product_name
            product_category
            core_category
            product_sku
            quantity_ordered
            product_sale_price {
              value
              currency
            }
            applied_sale_rules {
              rule_id
              name
              description
              is_gift
            }
            billing_interval
          }
          total {
            base_grand_total {
              currency
              value
            }
            discounts {
              amount {
                currency
                value
              }
              label
            }
            grand_total {
              currency
              value
            }
            shipping_handling {
              amount_excluding_tax {
                currency
                value
              }
              amount_including_tax {
                currency
                value
              }
              discounts {
                amount {
                  currency
                  value
                }
              }
              taxes {
                amount {
                  currency
                  value
                }
                rate
                title
              }
              total_amount {
                currency
                value
              }
            }
            subtotal {
              currency
              value
            }
            taxes {
              amount {
                currency
                value
              }
              rate
              title
            }
            total_giftcard {
              currency
              value
            }
            total_shipping {
              currency
              value
            }
            total_tax {
              currency
              value
            }
          }
        }
      }
    }
  }
`
