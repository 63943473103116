import { useState, useEffect } from "react"

/**
 *
 * @param {string} key
 * @param {{}} defaultValue
 * @returns {[any,((value: any) => void)]}
 */
const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    let currentValue

    try {
      if (typeof defaultValue === "string") {
        currentValue = String(defaultValue)
        return currentValue
      }

      currentValue = JSON.parse(
        localStorage.getItem(key) || String(defaultValue)
      )
    } catch (error) {
      currentValue = defaultValue
    }

    return currentValue
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [value, key])

  return [value, setValue]
}

export { useLocalStorage }
