import styled from "@emotion/styled"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"

export const SectionLine = styled.div`
  height: 1px;
  width: calc(100% - 48px);
  background-color: ${theme.wild.color.nova.transparency.black[20]};
  margin: 0 auto;
  ${queries.medium`
    width: calc(100% - 64px);
  `}
  ${queries.large`
    width: calc(100% - 96px);
    max-width: 1380px;
  `}
`
