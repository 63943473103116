import React, { useEffect, useState, useContext } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"

import CartIcon from "../../../../assets/icons/wild/cart-icon.svg"

import MiniCart from "../../global/minicart"
import CartContext from "../../../../context/CartContext"
import { useLocalStorage } from "../../../../hooks/useLocalStorage"
import { handleNavigationSegmentEvent } from "./utils/segmentEvents"

const envUrl = process.env.GATSBY_MAGENTO_URL

const HeaderCartWrapper = styled.div`
  padding: 7px;
  position: relative;
`
const CartIconHolder = styled.div`
  svg {
    width: 20px;
    height: 21px;
  }
  ${queries.medium`
    svg {
      width: 19px;
      height: 20px;
    }
  `}
`

const CartQty = styled.span`
  color: ${theme.wild.color.nova.base.black};
  background: ${theme.wild.color.nova.brand.accent[80]};
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 9px;
  font-weight: 700;
  line-height: 9px;
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 3px 0;

  &.hide {
    display: none;
  }
`

const HeaderCartIcon = ({ location }) => {
  const [cartGender, setCartGender] = useState("")
  const [cartLocalStorage, setCartLocalStorage] = useLocalStorage("cart")

  const cartState = useContext(CartContext)

  const setCartStateFromResponse = (cartJson) => {
    cartState.setCart(cartJson)
    setCartLocalStorage(cartJson)
    if (typeof cartJson === "object") {
      let gender = ""
      if (cartJson.items !== []) {
        if (Object.keys(cartJson.items).length > 0) {
          Object.keys(cartJson.items).forEach((item) => {
            let product = cartJson.items[item]
            if (product?.product_gender === "Women") {
              gender = "women"
            }
          })
        }
      }
      setCartGender(gender)
    }
  }

  useEffect(() => {
    const cartItemsFromLocalStorage = cartLocalStorage?.items
    let cartCount = 0

    if (cartItemsFromLocalStorage) {
      cartCount = Object.keys(cartItemsFromLocalStorage)?.length || 0
    }

    /**
     * skip the header call and load from localstorage.
     * unless account page
     *   case = user logged in to account with items already in cart
     */
    if (
      cartLocalStorage &&
      cartCount > 0 &&
      location?.pathname !== "/account/"
    ) {
      setCartStateFromResponse(cartLocalStorage)
      return
    }

    /*
     * get a token from site to use locally. DO NOT COMMIT!
     * url = `${envUrl}rest/V1/nutrafol-carts/V9IlpaJOcXgyMgEVxWlnGBQlnQfGm1uK`
     */
    const getCart = () => {
      let url = `${envUrl}rest/V1/nutrafol-carts/`
      cartState.setIsCartFetching(true)
      fetch(url, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (typeof resJson !== "object") {
            const cartJson = JSON.parse(resJson)
            setCartStateFromResponse(cartJson)
          }
        })
        .catch((error) => {
          console.warn("Fetch error: ", error)
        })
        .finally(() => cartState.setIsCartFetching(false))
    }

    getCart()
  }, [])

  return (
    <>
      <HeaderCartWrapper>
        <Link
          to={`/checkout/cart`}
          className="icon-holder icon-cart"
          id="cart-total-wrap"
          onClick={() =>
            handleNavigationSegmentEvent({
              destination: "/checkout/cart/",
            })
          }
        >
          <CartIconHolder>
            <CartIcon />
          </CartIconHolder>
          <CartQty
            className={`num-holder ${cartState.cartNum < 1 ? "hide" : ""}`}
          >
            <span id="cart-total-items">{cartState.cartNum}</span>
          </CartQty>
        </Link>
        <input type="hidden" id="cart-gender" value={cartGender} />
      </HeaderCartWrapper>
      <MiniCart />
    </>
  )
}

export default HeaderCartIcon
