import React, { useContext } from "react"
import { navigate } from "gatsby"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"

import { QuizCTAWrapper } from "./search.styles"

import { SearchContext } from "./searchcontext"

const QuizCTA = ({ isMobile = false, isBottom = false }) => {
  const { searchState } = useContext(SearchContext)
  const quizData = localStorage.getItem("quizResultsInfo")

  if (quizData) {
    return null
  }

  return (
    <QuizCTAWrapper
      className={`
      ${isMobile ? "hide-md-up" : "show-md-up"}
      ${!isBottom ? "scroll-shadow" : ""}
    `}
    >
      <ButtonWild
        arrowRight
        label="Take the Quiz for a Personalized Regimen"
        onClick={() => {
          segmentEvent("Search Clicked", {
            name: "Search",
            location: "navigation",
            state: searchState,
            text: "Take the Quiz for a Personalized Regimen",
            type: "Link",
            destination: "/quiz/",
          })
          navigate("/quiz/")
        }}
        className="block mt-6 w-full max-w-[800px] mb-4"
      />
    </QuizCTAWrapper>
  )
}

export default QuizCTA
