import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react"
import { useQuery } from "react-apollo"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"

import UserContext from "../../context/UserContext"
import { SearchContext } from "./searchcontext"

import CloseIcon from "../../assets/icons/master/search-close.svg"
import SearchIcon from "../../assets/icons/master/search-magnify-glass.svg"

import { getSubscriptionState, reformatSubscriptions } from "./utils"

import { useOutsideClick } from "../../hooks/useOutsideClick"
import { useIsScrollTopBottom } from "../../hooks/useIsScrollTopBottom"

import {
  HeaderContainer,
  InputContainer,
  SearchContainer,
} from "./search.styles"
import SearchResults from "./searchresults"
import LoadingSpinner from "../loading/loadingspinner"

import { getCustomerOrdersAndSubscriptions } from "../../queries/accountorders"
import QueryText from "./querytext"
import ArrowBack from "../../assets/icons/master/arrow-back.svg"

/**
 *
 * @returns {Element}
 * @constructor
 */
const Search = () => {
  const { isLoggedIn } = useContext(UserContext)
  const {
    searchClient,
    search,
    setSearch,
    searchState,
    setSearchState,
    resultSearchVisible,
    setResultSearchVisible,
    setSubscriptionsAndOrders,
    closeSearchDropdown,
    noResultsFound,
  } = useContext(SearchContext)

  const [isSearchMounted, setIsSearchMounted] = useState(false)
  const [searchedItem, setSearchItem] = useState({})
  const scrollContainer = useRef(null)
  const inputRef = useRef(null)
  const [searchData, setSearchData] = useState([])
  const [isLoadingAlgolia, setIsLoadingAlgolia] = useState(false)
  const [isLoadingSearch, setIsLoadingSearch] = useState(true)
  const resultShownSegmentTimer = useRef()

  const { isTop, isBottom, onScrollHandler } = useIsScrollTopBottom()

  const searchRef = useOutsideClick(() => {
    if (isSearchMounted) {
      closeSearchDropdown()
    }
  })

  const [suggestions] = searchData?.filter(
    (suggestion) =>
      suggestion.index === "Products_query_suggestions" ||
      suggestion.index === "Zendesk_query_suggestions"
  )

  const suggestedHits = suggestions?.hits || []

  const { data: subscriptionsAndOrders, loading: isLoadingSubscriptions } =
    useQuery(getCustomerOrdersAndSubscriptions, {
      skip: !isLoggedIn,
      fetchPolicy: "network-only",
    })

  useEffect(() => {
    if (!isLoadingAlgolia && !isLoadingSubscriptions) {
      setIsLoadingSearch(false)
    }
  }, [isLoadingAlgolia, isLoadingSubscriptions])

  useEffect(() => {
    if (scrollContainer?.current) {
      onScrollHandler(scrollContainer.current)
    }
  }, [onScrollHandler, scrollContainer])

  const getSearchResultsFromAlgolia = useCallback(
    async (query) => {
      const queries = [
        {
          indexName: process.env.GATSBY_ALGOLIA_PRODUCTS_INDEX_NAME,
          query,
          params: {
            hitsPerPage: 4,
            clickAnalytics: true,
          },
        },
        {
          indexName: "Zendesk_production",
          query,
          params: {
            hitsPerPage: 10,
            clickAnalytics: true,
          },
        },
        {
          indexName: "Pages",
          query,
          params: {
            hitsPerPage: 3,
            clickAnalytics: true,
          },
        },
        {
          indexName: "Products_query_suggestions",
          query,
        },
        {
          indexName: "Zendesk_query_suggestions",
          query,
        },
      ]

      try {
        setIsLoadingAlgolia(true)
        const { results } = await searchClient.multipleQueries(queries)
        setSearchData(results)
      } catch (error) {
        // @TODO what to do if error?
        console.error(`Algolia API error multipleQueries ${error}`)
      } finally {
        setIsLoadingAlgolia(false)
      }

      setResultSearchVisible({ product: false, article: false })
    },
    [searchClient, setResultSearchVisible]
  )

  useEffect(() => {
    getSearchResultsFromAlgolia(search)
  }, [getSearchResultsFromAlgolia, search])

  // Auto Focus Input on Search Modal Open
  useEffect(() => {
    if (!inputRef.current) return
    inputRef.current.focus()
  }, [inputRef])

  useEffect(() => {
    if (!isLoadingSubscriptions) {
      if (subscriptionsAndOrders) {
        setSubscriptionsAndOrders(reformatSubscriptions(subscriptionsAndOrders))
      }
      const subState = getSubscriptionState(subscriptionsAndOrders)
      setSearchState(subState)
    }
  }, [
    isLoadingSubscriptions,
    setSearchState,
    setSubscriptionsAndOrders,
    subscriptionsAndOrders,
  ])

  useEffect(() => {
    setIsSearchMounted(true)
  }, [])

  /**
   *
   * @param {{}} event
   * @returns {Promise<void>}
   */
  const onHandleEnterKeyFromSearchedItem = async (event) => {
    if (event.key !== "Enter") return
    const searchValue = event.target.value
    segmentEvent("Search Entered", {
      name: "Search",
      location: "navigation",
      state: searchState,
      search_term: searchValue,
    })
    segmentEvent("Search Results Shown", {
      name: "Search",
      location: "navigation",
      search_term: searchValue,
      results_found: !noResultsFound,
      state: "results",
    })
    setSearch(searchValue)
    getSearchResultsFromAlgolia(searchValue)
    inputRef.current.blur()
  }

  return (
    <div ref={searchRef}>
      <SearchContainer
        ref={scrollContainer}
        onScroll={(e) => onScrollHandler(e.target)}
      >
        <HeaderContainer className={!isTop ? "scroll-shadow" : ""}>
          <div className={"flex items-center md:mb-2 mx-[-16px] md:mx-0"}>
            {(resultSearchVisible.product || resultSearchVisible.article) && (
              <button
                type="button"
                className={"header-button"}
                onClick={() => {
                  setResultSearchVisible({ article: false, product: false })
                }}
              >
                <ArrowBack />
                <TextElement
                  text={"Back"}
                  element={"span"}
                  className={"header-button-text ml-2"}
                />
              </button>
            )}
            <button
              className={"header-button ml-auto"}
              onClick={closeSearchDropdown}
            >
              <TextElement
                text={"Close"}
                element={"span"}
                className={"header-button-text"}
              />
            </button>
          </div>
          <InputContainer className="mb-2 md:mb-4">
            <input
              name="search"
              id="search"
              type="text"
              autoComplete="search"
              value={search}
              ref={inputRef}
              maxLength="50"
              onChange={(e) => {
                if (search.length === 0 && e.target.value.length) {
                  segmentEvent("Search Started", {
                    name: "Search",
                    location: "navigation",
                    state: searchState,
                  })
                }
                if (search.length > 0 && e.target.value.length === 0) {
                  segmentEvent("Search Cleared", {
                    name: "Search",
                    location: "navigation",
                    state: searchState,
                  })
                }
                if (e.target.value.length) {
                  if (resultShownSegmentTimer.current) {
                    clearTimeout(resultShownSegmentTimer.current)
                  }
                  resultShownSegmentTimer.current = setTimeout(() => {
                    segmentEvent("Search Results Shown", {
                      name: "Search",
                      location: "navigation",
                      search_term: e.target.value,
                      results_found: !noResultsFound,
                      state: "results",
                    })
                  }, 500)
                }
                setSearch(e.target.value)
              }}
              placeholder={"Search for products and articles"}
              className={"search-input rounded-lg"}
              onKeyDown={onHandleEnterKeyFromSearchedItem}
            />
            <div className="search-controls">
              {Boolean(search.length) ? (
                <button
                  onClick={() => {
                    segmentEvent("Search Cleared", {
                      name: "Search",
                      location: "navigation",
                      state: searchState,
                    })
                    setSearch("")
                    inputRef.current.focus()
                  }}
                  className={"clear-icon"}
                  aria-label={"clear search"}
                >
                  <CloseIcon />
                </button>
              ) : null}
              <button
                onClick={() => {
                  segmentEvent("Search Entered", {
                    name: "Search",
                    location: "navigation",
                    state: searchState,
                    search_term: search,
                  })
                  segmentEvent("Search Results Shown", {
                    name: "Search",
                    location: "navigation",
                    search_term: search,
                    results_found: !noResultsFound,
                    state: "results",
                  })
                  getSearchResultsFromAlgolia(search)
                }}
                aria-label={"search"}
              >
                <SearchIcon />
              </button>
            </div>
          </InputContainer>
          {Boolean(search.length) && (
            <QueryText
              query={search}
              items={suggestedHits}
              data={searchData}
              onSuggestedClicked={({ query }) => {
                segmentEvent("Search Entered", {
                  name: "Search",
                  location: "navigation",
                  state: searchState,
                  search_term: query,
                })
                segmentEvent("Search Results Shown", {
                  name: "Search",
                  location: "navigation",
                  search_term: query,
                  results_found: !noResultsFound,
                  state: "results",
                })
                setSearch(query)
              }}
            />
          )}
        </HeaderContainer>
        {isLoadingSearch ? (
          <LoadingSpinner />
        ) : (
          <SearchResults
            data={searchData}
            querySearch={search}
            searchedItem={searchedItem}
            setSearchItem={setSearchItem}
            suggestedHits={suggestedHits}
            isBottom={isBottom}
          />
        )}
      </SearchContainer>
    </div>
  )
}

export default Search
