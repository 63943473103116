import gql from 'graphql-tag'

export const addCardMutation = gql`
    mutation addCardMutation($input: TokenBaseCardCreateInput!){
        createTokenBaseCard(
            input: $input
        ){
          hash
        }
    }
`

export const editCardMutation = gql`
  mutation editCardMutation($input: TokenBaseCardUpdateInput!){
    updateTokenBaseCard(
      input: $input
    ){
      hash,
      active
    }

  }
`

export const getTokenBaseCards = gql`
  query {
    tokenBaseCheckoutConfig(method: "authnetcim"){
      clientKey
      apiLoginId
      sandbox
    }
    tokenBaseCards {
      id,
      label,
      hash,
      method,
      active,
      address {
        region {
          region,
          region_id
        },
        region_id,
        country_id,
        street,
        company,
        telephone,
        postcode,
        city,
        firstname,
        lastname
      },
      additional {
        cc_type,
        cc_last4,
        cc_exp_year,
        cc_exp_month

      }
    }
  }
`

export const getRecaptchaKey = gql`
  query {
    nutrafolRecaptchaV3 {
      enabled
      public_key
      required_recaptcha_mutations
    }
  }
`