import gql from "graphql-tag"

export const loginGetTokenMutation = gql`
  mutation GetCustomerToken($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
      weak_password
    }
  }
`

export const isCustomerAuthorized = gql`
  query {
    customer {
      firstname
      lastname
      telephone
      email
      entity_id
    }
  }
`
