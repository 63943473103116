import React, { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

import popupHelper from "@nutrafol/popup-helper"

import CloseIcon from "../../assets/icons/close-x-icon.svg"

const ModalHeader = styled.div`
  text-align: right;
  padding-top: ${theme.spacing.medium};
  @media (min-width: 768px) {
    transform: translate(16px);
  }
`
const ModalBody = styled.div``

const CloseWrapper = styled.button``

const Modal = (props) => {

  const Component = props.component
  const { hideModal, propsObject, modalSize, customZIndex, onOpen = true} = props
  const modalTopRef = useRef(null)

  const zIndex = customZIndex ? customZIndex : 15

  const ModalOverlay = styled.div`
    position: fixed;
    z-index: ${zIndex};
    background: #22202F;
    opacity: .5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `
  const ModalWrap = styled.div`
    position: fixed;
    z-index: ${zIndex + 1};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${theme.color.white};
    padding: 0 16px ${theme.spacing.medium};
    overflow: auto;
    @media (min-width: 768px) {
      width: 80%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 1200px;
      padding: 0 48px 48px;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0,0,0,.5);
      max-height: calc(100% - 50px);
      &.small {
        max-width: 725px
      }
      &.medium {
        max-width: 864px
      }
    }
    &.c-OneToThreeWrapper {
      button:focus {
        outline: #da9a35;
        outline-style: auto;
      }   
    }
  `

  const hideModalProxy = () => {
    hideModal()
    const body = document.querySelectorAll("body")[0]
    body && body.classList &&  body.classList.remove("no-scroll")
  }

  useEffect(() => {
    onOpen && popupHelper.onOpen({
      wrapper: "Modal",
      desktopEl: "ModalBody",
      mobileEl: "ModalBody",
      closeEl: "CloseWrapper"
    })
    const body = document.querySelectorAll("body")[0]
    body && body.classList && body.classList.add("no-scroll")
  }, [onOpen])

  const scrollToTop = () => {
    modalTopRef.current && modalTopRef.current.scroll({top:0,behavior:'smooth'})
  }

  //let originalTop = null

  const onScroll = ()=>{
    //hide google autosuggest
    let els = document.querySelectorAll('.pac-container')
    if(els.length){
      els.forEach(el=> {
        if (el.style.display !== "none"){
          el.style.display = "none"
        }
      })
      /*
       * does not work porperly
       * if (el.style.display !== "none") {
       *   if (originalTop === null) {
       *     originalTop = parseInt(el.style.top)
       *   }
       *   if (!isNaN(originalTop)) {
       *     el.style.top = originalTop - modalTopRef.current.scrollTop - 3 + 'px'
       *   }
       * }
       */
    }
  }
  return (
    <>
      <ModalOverlay onClick={hideModalProxy} />
      <ModalWrap id={`Modal`} className={modalSize ? modalSize : ''} onScroll={onScroll} ref={modalTopRef}>
        <ModalHeader>
          <CloseWrapper id={`CloseWrapper`} onClick={hideModalProxy} >
            <CloseIcon/>
          </CloseWrapper>
        </ModalHeader>
        <ModalBody id={`ModalBody`}>
          <Component {...propsObject} scrollToTop={() => scrollToTop()} hideModal={hideModalProxy}/>
        </ModalBody>
      </ModalWrap>
    </>
  )
}

export default Modal