import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

const TRACKMODE =
  typeof window !== `undefined` &&
  typeof window.analytics !== `undefined` &&
  typeof window.analytics.track !== `undefined`

export const blogPostShareClicked = (
  post_categories,
  post_tags,
  post_title,
  social_network
) => {
  if (TRACKMODE) {
    segmentEvent("Blog Post Share Clicked", {
      post_categories: post_categories,
      post_tags: post_tags,
      post_title: post_title,
      social_network: social_network,
    })
  }
}

export const videoPlaySegmentEvent = (location, text) => {
  if (TRACKMODE) {
    segmentEvent("Newsroom Video Clicked", {
      text: text,
    })
  }
}

export const outboundLinkClicked = (destination, text, type) => {
  if (TRACKMODE) {
    segmentEvent("Outbound Link Clicked", {
      destination: destination,
      text: text,
      type: type,
    })
  }
}

export const marketingCTAClicked = (destination, text, type) => {
  if (TRACKMODE) {
    segmentEvent("Marketing CTA Clicked", {
      destination: destination,
      text: text,
      type: type,
    })
  }
}

export const orderDetailsViewed = (order) => {
  if (TRACKMODE) {
    const eventName = `Order Details Viewed`
    const eventData = {
      order_entity_id: atob(order.id),
      order_increment_id: order.number,
    }
    segmentEvent(eventName, eventData)
  }
}

export const notificationBarViewed = (notification_title, message) => {
  return segmentEvent("Account Notification Shown", {
    notification: notification_title,
    type: message.type === "error" ? "warning" : "standard",
  })
}

export const notificationBarClicked = (notification_title, message) => {
  return segmentEvent("Account Notification Clicked", {
    notification: notification_title,
    type: message.type === "error" ? "warning" : "standard",
  })
}

export const notificationBarClosed = (notification_title, message) => {
  return segmentEvent("Account Notification Closed", {
    notification: notification_title,
    type: message.type === "error" ? "warning" : "standard",
  })
}

export const hairJourneyCTAClick = (product_name, module_state) => {
  return segmentEvent("Account Module CTA Clicked", {
    destination: "/quiz/",
    module_name: "Hair Journey",
    module_state: module_state,
    product_name: product_name,
    text: "Start Your Hair Journey",
    type: "button",
  })
}

export const hairJourneyLearnMoreClick = (product_name) => {
  return segmentEvent("Account Module CTA Clicked", {
    destination: "modal - benefits",
    module_name: "Hair Journey",
    module_state: "active_subscription",
    product_name: product_name.replace(/\\/g, ""),
    text: "Learn More",
    type: "link",
  })
}

export const hairJourneyTabClick = (text, product_name) => {
  return segmentEvent("Account Module CTA Clicked", {
    destination: "/account/",
    module_name: "Hair Journey",
    module_state: "active_subscription",
    product_name: product_name,
    text: text,
    type: "tab",
  })
}

export const subscriptionErrorViewed = (email, user_id) => {
  return segmentEvent("Account Subscription Error Viewed", {
    email: email,
    user_id: user_id,
  })
}

export const ppMarketingCtaClick = () => {
  return segmentEvent("Marketing CTA Clicked", {
    destination: "/nutrafol-postpartum-for-women/",
    text: "Shop Now",
    type: "button",
  })
}

export const ppMarketingCtaClickViewAll = () => {
  return segmentEvent("Marketing CTA Clicked", {
    destination: "/blog/",
    text: "View All",
    type: "text",
  })
}

export const ppBlogPostClick = (title) => {
  return segmentEvent("Feature Blog Post Clicked", {
    blog_post: title,
  })
}

export const accountErrorViewed = (obj) => {
  return segmentEvent("Account Error Viewed", obj)
}

export const marketingSocialClick = () => {
  return segmentEvent("Marketing CTA Clicked", {
    destination: "mailto:pr@nutrafol.com",
    text: "Email Us",
    type: "button",
  })
}
