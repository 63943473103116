import React, { useEffect, useState, useContext, useCallback } from "react"

import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"

import { SearchResultsContainer, Disclaimer } from "./search.styles"

import DefaultSearch from "./defaultsearch"
import NoResults from "./noresults"
import SuggestedSearch from "./suggestedsearch"
import ArticleSearch from "./articlesearch"
import ProductSearch from "./productsearch"
import LoadingSpinner from "../loading/loadingspinner"

import QuizCTA from "./quizcta"

import { matchAlgoliaToContentful } from "./utils"

import { SearchContext } from "./searchcontext"

/**
 * Ranking order:
 * 1. Products from contentful to Algolia (product/categories)
 * 2. Zendesk data to Algolia (faq/articles)
 *
 * @param {{}} data
 * @param {string} querySearch
 * @param {Function} onClick
 * @param {Function} setResultSearchVisible
 * @param {{}} resultSearchVisible
 * @param {{}} searchedItem
 * @param {Function} setSearchItem
 * @param {[]} suggestedHits
 * @returns {JSX.Element}
 * @constructor
 */
const SearchResults = ({
  data,
  querySearch,
  searchedItem,
  setSearchItem,
  suggestedHits = [],
  isBottom,
}) => {
  const [zendeskData] = data.filter(
    (zenData) => zenData.index === "Zendesk_production"
  )
  const [products] = data.filter(
    (productData) =>
      productData.index === process.env.GATSBY_ALGOLIA_PRODUCTS_INDEX_NAME
  )
  const [pages] = data.filter((pageData) => pageData.index === "Pages")

  const {
    searchClient,
    contentfulProducts,
    defaultFAQs,
    defaultFAQsLoading,
    resultSearchVisible,
    setResultSearchVisible,
    setNoResultsFound,
  } = useContext(SearchContext)

  const hasMatchedProductSearch = Boolean(products?.hits.length)
  const hasMatchedZendeskSearch = Boolean(zendeskData?.hits.length)
  const isNoResultsFound = !hasMatchedProductSearch && !hasMatchedZendeskSearch
  const { product, article } = resultSearchVisible

  const [isAlgoliaLoading, setIsLoadingAlgolia] = useState(true)
  const [categories, setCategories] = useState([])

  const getDefaultCategoriesFromAlgolia = useCallback(async () => {
    const index = searchClient.initIndex("Products")
    try {
      setIsLoadingAlgolia(true)
      const { hits, queryID } = await index.search("", {
        clickAnalytics: true,
      })

      const matchedProducts = matchAlgoliaToContentful(contentfulProducts, hits)

      const cats = {
        "Hair Growth Supplements": [],
        "Wellness Boosters": [],
        "Scalp Care": [],
      }

      matchedProducts.forEach((product) => {
        const productWithQueryID = {
          ...product,
          queryID,
        }
        if (productWithQueryID.isCore) {
          cats["Hair Growth Supplements"].push(productWithQueryID)
        }
        if (productWithQueryID.isBooster) {
          cats["Wellness Boosters"].push(productWithQueryID)
        }
        if (productWithQueryID.isScalp) {
          cats["Scalp Care"].push(productWithQueryID)
        }
      })

      setCategories(cats)
    } catch (error) {
      // @TODO what to do if error?
      console.error(`Algolia API error multipleQueries ${error}`)
    } finally {
      setIsLoadingAlgolia(false)
    }

    setResultSearchVisible({ product: false, article: false })
  }, [contentfulProducts, searchClient, setResultSearchVisible])

  useEffect(() => {
    getDefaultCategoriesFromAlgolia()
  }, [getDefaultCategoriesFromAlgolia])

  const [defaultPages, setDefaultPages] = useState([])

  useEffect(() => {
    if (!hasMatchedProductSearch && !hasMatchedZendeskSearch) {
      setNoResultsFound(true)
    } else {
      setNoResultsFound(false)
    }
  }, [hasMatchedProductSearch, hasMatchedZendeskSearch, setNoResultsFound])

  useEffect(() => {
    if (pages && defaultPages.length === 0) {
      setDefaultPages(pages?.hits)
    }
  }, [pages, defaultPages])

  const onSearchClicked = (event, product) => {
    setSearchItem(product)
    /**
     * We can use `imageId` for now to determine if article or product
     * there should be a better way, this is okay for now.
     */
    if (product?.imageId) {
      setResultSearchVisible({ product: true, article: false })
      return
    }

    setResultSearchVisible({ product: false, article: true })
  }

  if (defaultFAQsLoading || isAlgoliaLoading) {
    return (
      <div className="relative w-full h-full">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <>
      <SearchResultsContainer className={"flex flex-col"}>
        {!(product || article) &&
          (!Boolean(querySearch.length) ? (
            <DefaultSearch
              title={"Popular Products"}
              categories={categories}
              faq={defaultFAQs}
              onClick={onSearchClicked}
            />
          ) : isNoResultsFound ? (
            <NoResults
              text={querySearch}
              faq={defaultFAQs}
              pages={defaultPages}
              category={categories["Hair Growth Supplements"] || {}}
              suggestedHits={suggestedHits}
              onClick={onSearchClicked}
              querySearch={querySearch}
            />
          ) : (
            <SuggestedSearch
              products={products}
              articles={zendeskData?.hits}
              pages={pages?.hits}
              onClick={onSearchClicked}
              querySearch={querySearch}
            />
          ))}
        {product ? (
          <ProductSearch
            data={searchedItem}
            onClick={onSearchClicked}
            queryID={products?.queryID}
          />
        ) : null}
        {article ? (
          <ArticleSearch data={searchedItem} onClick={onSearchClicked} />
        ) : null}
        {product ? (
          <Disclaimer>
            <div className={"footer-box"}>
              <TextElement
                text={
                  "*These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease."
                }
                element="p"
                className="body--small text-master-base-alt-disabled"
              />
            </div>
          </Disclaimer>
        ) : null}
      </SearchResultsContainer>
      {!product && !article ? (
        <QuizCTA isMobile={true} isBottom={isBottom} />
      ) : null}
    </>
  )
}

export default SearchResults
