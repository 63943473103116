import React from "react"
import styled from "@emotion/styled"
import { Flex, Box } from "@rebass/grid/emotion"
import { Link } from "gatsby"

import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"

import LogoCircle from "../../../../assets/icons/wild/nutrafol-logo-circle.svg"

import { data } from "./data"
import AdChoicesLink from "../../global/adchoiceslink"
import AdChoicesWrap from "../../global/adchoiceslink/adchoiceswrap"

const FooterContainer = styled.div`
  overflow-x: hidden;
`
const FooterWrap = styled(Flex)`
  .refbox {
    @media (min-width: 768px) and (max-width: 1280px) {
      padding: 0 80px 0 0;
    }
  }
`
const FooterLink = styled(Link)`
  color: ${theme.wild.color.master.base.black};
  font-size: 12px;
  line-height: 16px;
  font-family: ${theme.wild.font.dinamo.regular};
  display: inline-block;
`

const Wrap = styled.div`
  width: 100%;
  max-width: 1170px;
  padding: 15px 0px;
  margin-left: auto;
  margin-right: auto;
  li,
  li.ad-choices {
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &.ad-choices span.textelement.body--regular {
      font-size: 12px;
      line-height: 16px;
    }
    @media (min-width: 1024px) {
      width: 130px;
    }
  }
`

const FooterSimpleWild = (props) => {
  const copyrightWidth = props.referenceText
    ? [1, 1, 7 / 16, 3 / 10]
    : [1, 1, 1 / 2]
  const linksWidth = props.referenceText
    ? [1, 1, 7 / 16, 2 / 10]
    : [1, 1, 1 / 3]
  const logoWidth = props.referenceText
    ? [1, 1, 1 / 8, 1 / 10]
    : [1, null, 1 / 6]

  return (
    <footer>
      <FooterContainer
        style={{ backgroundColor: theme.wild.color.master.secondary.alt }}
      >
        <Wrap>
          <FooterWrap
            flexWrap="wrap"
            mx={[-2]}
            px={[15, 15, 25]}
            py={[30, 30, 60, 80]}
          >
            <Box width={logoWidth} px={2} mb={[40, 40, 0]}>
              <LogoCircle />
            </Box>
            <Box width={copyrightWidth} px={2} mb={[40, 40, 0]} pr={[2, 2, 4]}>
              <TextElement
                element="p"
                text={data.copy.text}
                className="body--small"
                styles={{
                  color: theme.wild.color.master.base.black,
                  marginBottom: "8px",
                }}
              />
              <TextElement
                element="p"
                text={data.copy.title}
                className="body--small"
                styles={{ color: theme.wild.color.master.base.black }}
              />
              <a
                href={"/sitemap-0.xml"}
                aria-label={`Sitemap (Opens in new tab)`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TextElement
                  element="p"
                  className="body--small underline"
                  text={"Sitemap"}
                  styles={{ color: theme.wild.color.master.base.black }}
                />
              </a>
              <div className="hidden sm:flex md:hidden">
                <Box width={1} mt={16}>
                  <TextElement
                    element="p"
                    text="Legal"
                    className="body--small-b"
                    styles={{ color: theme.wild.color.master.primary.default }}
                  />
                  <Flex flexWrap="wrap" mt={[15, 15, 8]}>
                    <Box width={1}>
                      <ul>
                        <li>
                          <FooterLink to="/men/terms-of-use/">{`Terms of Use`}</FooterLink>
                        </li>
                        <li>
                          <FooterLink to="/privacy-policy/">{`Privacy Policy`}</FooterLink>
                        </li>
                        <li>
                          <FooterLink to="/consumer-health-data-privacy-policy/">
                            Consumer Health Data Privacy Policy
                          </FooterLink>
                        </li>
                        <li className="ad-choices">
                          <AdChoicesLink />
                        </li>
                        <li>
                          <FooterLink to="/privacy-request/">
                            Do Not Sell or Share My Personal Information
                          </FooterLink>
                        </li>
                        <li>
                          <FooterLink to="/privacy-request/?preselected=limit">
                            Limit Use of My Sensitive Personal Information
                          </FooterLink>
                        </li>
                      </ul>
                    </Box>
                    {/*{buildEvidon()}*/}
                  </Flex>
                </Box>
              </div>
            </Box>
            <Box
              width={linksWidth}
              px={2}
              mb={[40, 40, 0]}
              className="sm:hidden md:block"
            >
              <TextElement
                element="p"
                text="Legal"
                className="body--small-b"
                styles={{ color: theme.wild.color.master.primary.default }}
              />
              <Flex flexWrap="wrap" mt={[15, 15, 8]}>
                <Box width={1}>
                  <ul>
                    <li>
                      <FooterLink to="/men/terms-of-use/">{`Terms of Use`}</FooterLink>
                    </li>
                    <li>
                      <FooterLink to="/privacy-policy/">{`Privacy Policy`}</FooterLink>
                    </li>
                    <li>
                      <FooterLink to="/consumer-health-data-privacy-policy/">
                        Consumer Health Data Privacy Policy
                      </FooterLink>
                    </li>
                    <li className="ad-choices">
                      <AdChoicesLink />
                    </li>
                    <li>
                      <FooterLink to="/privacy-request/">
                        Do Not Sell or Share My Personal Information
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink to="/privacy-request/?preselected=limit">
                        Limit Use of My Sensitive Personal Information
                      </FooterLink>
                    </li>
                  </ul>
                </Box>
                {/*{buildEvidon()}*/}
              </Flex>
            </Box>
            <Box
              width={[1, null, 7 / 16, 4 / 10]}
              pl={[2, 2, 24]}
              pb={[60, 60, 0]}
              className={`refbox`}
            >
              <TextElement
                element="p"
                text={props.referenceText}
                className="body--small"
                styles={{ color: theme.wild.color.master.base.black }}
              />
            </Box>
          </FooterWrap>
        </Wrap>
      </FooterContainer>
      <AdChoicesWrap />
    </footer>
  )
}

export default FooterSimpleWild
