import React from "react"

import styled from "@emotion/styled"

import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

const LinkHolder = styled.div`
  cursor: pointer;
  .action-link {
    color: ${theme.wild.color.master.primary.default};
    font-size: 14px;
    font-family: ${theme.wild.font.dinamo.mono};
    font-weight: ${theme.weight.mono};
    letter-spacing: 0px;
    line-height: 22px;
    border-bottom: 1px solid ${theme.wild.color.master.primary.default};
    display: inline;
  }
  &.show-md-up {
    margin-right: 25px;
    white-space: nowrap;
  }
`

const ActionLink = (props) => {
  const { actionText, actionFunc, actionLinkClick } = props

  const onClick = () => {
    if (typeof actionFunc === "function") {
      actionFunc()
    } else {
      console.error("actionFunc not found in ActionLink")
    }
    if (typeof actionLinkClick === "function") {
      actionLinkClick()
    }
  }

  return (
    <LinkHolder className={``} onClick={onClick}>
      <div className="action-link">{actionText}</div>
    </LinkHolder>
  )
}

export default ActionLink
