import React, { useContext } from "react"

import ProductList from "./productlist"

import { getFilteredCategories } from "./utils"
import { SearchSubtitle } from "./search.styles"

import { sortProducts } from "./utils"

import { SearchContext } from "./searchcontext"

/**
 * Categories and individual products are dynamically
 * ordered via contentful this order is modified when
 * a user has an active/canceled subscription, active subscriptions
 * re-order categories and canceled re-orders products.
 *
 * @param {[]} categories
 * @param {Function} onClick
 * @param {{}} subscriptions
 * @returns {React.JSX.Element}
 * @constructor
 */
const Categories = ({ categories }) => {
  const { subscriptionsAndOrders } = useContext(SearchContext)
  const filteredCategories = getFilteredCategories(
    categories,
    subscriptionsAndOrders
  )
  const purchasedSkus = Object.values(subscriptionsAndOrders).flat()

  return (
    <div className={"flex flex-col subtitle-images"}>
      {filteredCategories.map(([title, products], index) => {
        const sortedProducts = sortProducts(products, purchasedSkus).slice(0, 4)
        return (
          <div
            key={title}
            className={`${
              index === 0 ? "mb-1 sm:mb-2 md:mb-4" : "mb-4"
            } w-[calc(100%+16px)] sm:w-[100%]`}
          >
            <SearchSubtitle text={title} />
            <ProductList products={sortedProducts} />
          </div>
        )
      })}
    </div>
  )
}

export default Categories
