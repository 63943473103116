import React from "react"
import { Link } from "gatsby"
import Arrow from "../../../../assets/icons/wild/arrow-up-right.svg"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { Flex } from "@rebass/grid/emotion"

const destinationMap = {
  women: "/",
  men: "/men/",
  skin: `${process.env.GATSBY_MAGENTO_URL}skin/`,
}

const NavSwitcher = ({
  type = "Women",
  copy = `Switch to Nutrafol Women`,
  external = false,
  isGwpLp,
}) => {
  const destination = destinationMap[type?.toLowerCase()]
  const onRedirect = () => {
    segmentEvent("Navigation Clicked", {
      module_location: "navigation",
      destination,
      text: `Switch to ${type}`,
    })
  }
  return (
    <Flex
      className={`h-full items-center flex-1 toggler-gradient ${type} ${isGwpLp ? "!hidden" : ""}`}
    >
      {external ? (
        <a href={destination} className="text-[#22202F] text-[12px] flex">
          {copy} <Arrow />
        </a>
      ) : (
        <Link
          className="text-[#22202F] text-[12px] flex"
          to={destination}
          onClick={onRedirect}
        >
          {copy} <Arrow />
        </Link>
      )}
    </Flex>
  )
}

export default NavSwitcher
