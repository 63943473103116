import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { useQuery } from "react-apollo"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import LinkedItems from "./linkitems"

import ComparisonTable from "./comparisontable"
import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import LoadingSpinner from "../loading/loadingspinner"

import { requestArticle } from "../../queries/search"
import { ZENDESK_BUTTON, ZENDESK_TABLE } from "../../constants"

import SearchResultsLayout from "./searchresultslayout"

import { SearchContext } from "./searchcontext"

import {
  getFormattedButton,
  getInteractiveElements,
  stripZendeskSpecialStrings,
} from "./utils"

/**
 *
 * @param {{}} data
 * @param {[]} faq
 * @param {Function} onClick
 * @returns {Element}
 * @constructor
 */

const ArticleSearch = ({ data, onClick }) => {
  const { recommendClient, searchState } = useContext(SearchContext)
  const [relatedFAQsLoading, setRelatedFAQsLoading] = useState(true)
  const [relatedFAQs, setRelatedFAQs] = useState([])

  useEffect(() => {
    const fetchRelatedFAQs = async () => {
      try {
        const FAQs = await recommendClient.getRelatedProducts([
          {
            indexName: "Zendesk_production",
            objectID: data?.objectID,
            maxRecommendations: 5,
          },
        ])
        setRelatedFAQs(FAQs?.results[0]?.hits)
      } catch (error) {
        console.error(`Algolia API error related articles ${error}`)
      } finally {
        setRelatedFAQsLoading(false)
      }
    }
    fetchRelatedFAQs()
  }, [recommendClient, data?.objectID, relatedFAQs?.results?.hits])

  const { loading, data: articleData } = useQuery(requestArticle, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: data?.id,
    },
    context: {
      clientName: "searchLink",
    },
  })

  if (loading || relatedFAQsLoading) {
    return (
      <div className="relative w-full h-full">
        <LoadingSpinner />
      </div>
    )
  }

  if (!loading && !articleData?.ShowArticle?.article?.body) {
    return (
      <div className="text-center mx-auto">
        <TextElement
          className={"article-title title--small-i mb-2 sm:mb-4"}
          text="Something went wrong"
        />
        <TextElement
          className={"article-title body--large"}
          text="Sorry, something went wrong. Please try again later."
        />
      </div>
    )
  }

  const interactiveElements =
    getInteractiveElements(articleData?.ShowArticle?.article?.body) || []

  return (
    <SearchResultsLayout>
      <>
        <TextElement
          className={"article-title title--small-i mb-2 sm:mb-8"}
          text={articleData?.ShowArticle?.article?.title}
        />
        <div
          className={"article-body-text"}
          dangerouslySetInnerHTML={{
            __html: stripZendeskSpecialStrings(
              articleData?.ShowArticle?.article?.body
            ),
          }}
        />
        {interactiveElements.map((element, id) => (
          <div key={id}>
            {element === ZENDESK_TABLE && (
              <div className={"mt-12"}>
                <ComparisonTable />
              </div>
            )}
            {element.includes(ZENDESK_BUTTON) ? (
              <ButtonWild
                secondary
                arrowRight
                label={getFormattedButton(element)[0]}
                className={"w-full mt-9 capitalize"}
                onClick={() => {
                  segmentEvent("Search Clicked", {
                    name: "Search",
                    location: "navigation",
                    state: searchState,
                    text: getFormattedButton(element)[0],
                    destination: `/${getFormattedButton(element)[1]}/`,
                    type: "Link",
                  })
                  navigate(`/${getFormattedButton(element)[1]}`)
                }}
              />
            ) : null}
          </div>
        ))}
      </>
      <div>
        <LinkedItems title={"Related"} items={relatedFAQs} onClick={onClick} />
      </div>
    </SearchResultsLayout>
  )
}

export default ArticleSearch
