import { graphql } from "gatsby"
import { PRODUCT_IMAGES } from "./imagesMap"
import { HAIRGROWTHKIT } from "../constants"

export const productData = graphql`
  fragment ProductData on MAGENTO_Products {
    items {
      sku
      name
      subscription_type
      shipping_interval
      product_category
      old_price
      price_range {
        minimum_price {
          regular_price {
            value
          }
        }
      }
      product_core_category
      billing_interval
      purchase_type
      product_gender
      product_gender_new
      image {
        url
      }
      ... on MAGENTO_CustomizableProductInterface {
        options {
          title
          ... on MAGENTO_CustomizableDropDownOption {
            dropDown: value {
              title
              sku
              price
              price_type
            }
          }
        }
      }
    }
  }
`
export const attributeData = graphql`
  fragment AttributeData on MAGENTO {
    customAttributeMetadata(
      attributes: [
        { attribute_code: "subscription_type", entity_type: "catalog_product" }
        { attribute_code: "shipping_interval", entity_type: "catalog_product" }
        { attribute_code: "product_category", entity_type: "catalog_product" }
        {
          attribute_code: "product_core_category"
          entity_type: "catalog_product"
        }
        { attribute_code: "billing_interval", entity_type: "catalog_product" }
        { attribute_code: "purchase_type", entity_type: "catalog_product" }
        { attribute_code: "product_gender", entity_type: "catalog_product" }
        { attribute_code: "product_gender_new", entity_type: "catalog_product" }
        { attribute_code: "promotional_sku", entity_type: "catalog_product" }
        { attribute_code: "pill_count", entity_type: "catalog_product" }
        {
          attribute_code: "purchase_type_sub_category"
          entity_type: "catalog_product"
        }
        {
          attribute_code: "purchase_type_category"
          entity_type: "catalog_product"
        }
        {
          attribute_code: "product_sub_category"
          entity_type: "catalog_product"
        }
        { attribute_code: "number_of_refills", entity_type: "catalog_product" }
        {
          attribute_code: "number_of_growth_activator_bot"
          entity_type: "catalog_product"
        }
      ]
    ) {
      items {
        attribute_code
        attribute_options {
          value
          label
        }
      }
    }
  }
`

const getAttributeValue = (name, val, attributes) => {
  const numval = parseInt(val)
  if (isNaN(numval)) {
    return val
  }

  const attributeValues = attributes.items.filter(
    (attr) => attr.attribute_code === name
  )?.[0]?.attribute_options
  if (attributeValues?.length > 0) {
    const attributeValueFormatted = attributeValues.find(
      (value) => parseInt(value?.["value"]) === numval
    )
    if (attributeValueFormatted && attributeValueFormatted.label) {
      return attributeValueFormatted.label
    }
  }
  return val
}

const getAttributeCodes = (attributes) => {
  return attributes.items.map((attribute) => attribute.attribute_code)
}

const matchAttributeData = (products, attributes) => {
  const formattedProducts = products
  const attribute_codes = getAttributeCodes(attributes)
  Object.keys(products).forEach((sku) => {
    const product = products[sku] || {}
    Object.keys(product).forEach((attr) => {
      if (!!product[attr] && attribute_codes.includes(attr)) {
        formattedProducts[sku][attr] = getAttributeValue(
          attr,
          product[attr],
          attributes
        )
      }
    })
  })
  return formattedProducts
}

const createProductMap = (skus, products) => {
  const productsMap = {}
  //skus.map((sku) => productsMap[sku] = {})
  skus.forEach((sku) => {
    const product = products.items.filter((obj) => obj.sku === sku)
    productsMap[sku] = product[0]
  })
  return productsMap
}

/*
 *
 *
 *
 * new SKU style
 *
 *                       _      _                 _
 *                         | |    | |               | |
 *_ __ ___ _ __   __ _  ___| | __ | | ___   __ _  __| | ___ _ __
 *| '__/ _ \ '_ \ / _` |/ __| |/ / | |/ _ \ / _` |/ _` |/ _ \ '__|
 *| | |  __/ |_) | (_| | (__|   <  | | (_) | (_| | (_| |  __/ |
 *|_|  \___| .__/ \__,_|\___|_|\_\ |_|\___/ \__,_|\__,_|\___|_|
 *        | |
 *        |_|
 *
 */

/**
 **
 * WOM1-N01 is single purchase
 * @param product
 * @returns {boolean}
 */
export const isOneTimePurchase = (product) => {
  return product?.sku?.indexOf("-N") > -1
}

/**
 **
 * CBUILDER_PROMO-N01 is a promo purchase
 * @param product
 * @returns {boolean}
 */
export const isPromoPurchase = (product) => {
  return product?.sku?.indexOf("PROMO") > -1
}

/**
 **
 * CBUILDER_PROMO-N01 is a zero value purchase
 * @param product
 * @returns {boolean}
 */
export const isZeroValue = (product) => {
  return product?.product_sale_price?.value === 0
}

/**
 **
 * WOM1-S01 is a subscription
 * @param product
 * @returns {boolean}
 */
export const isSub = (product) => {
  return product?.sku?.indexOf("-S") > -1
}

/**
 **
 * api can return 1 or "1" =(
 * @param product
 * @returns {boolean}
 */
export const isSubCasted = (product) => {
  return parseInt(product?.is_subscription) === 1
}

export const isPackageCasted = (product) => {
  return parseInt(product?.is_package_product) === 1
}

export const isDuo = (product) => {
  return product?.purchase_type === "Growthplan 2"
}

export const isSkin = (product) => {
  return product?.sku.startsWith("SKIN")
}

export const has3packUpgrade = (product) => {
  return !!product?.three_pack_sku && product?.subscription_type === "Monthly"
}

export const hasChildProducts = (product) => {
  return product?.child_products?.length > 0
}

// we need to map legacy skus to repacked ones in order to keep using old prismic scalp pages
export const GASKU = "GACTIVATOR-N01"
export const GASKUMEN = "MGACTIVATOR-N01"
export const PROMOLIST3 = ["WOM3PROMO", "POS3PROMO", "BAL3PROMO", "MEN3PROMO"]
export const PROMOLIST6 = ["WOM6PROMO", "POS6PROMO", "BAL6PROMO", "MEN6PROMO"]

// we need to map legacy skus to repacked ones in order to keep using old cms scalp pages

export const skuToRepack = {
  CoreWom: "WOM1",
  CoreMen: "MENPRO1",
  CorPlus: "BAL1",
  "0000301": "POS1",
  "0000471": "BBLOCKER",
  "0000472": "RPURIFIER",
  "0000473": "SRELIEVER",
  "0000474": "HBALANCEKIT",
  "0000498": "SDEFENDER",
  "0000313": "GACTIVATOR",
}

export const repackToSku = {
  "WOM1-S01": "CoreWom",
  "MENPRO1-S01": "CoreMen",
  "BAL1-S01": "CorPlus",
  "POS1-S01": "0000301",
  "BBLOCKER-N01": "0000471",
  "RPURIFIER-N01": "0000472",
  "SRELIEVER-N01": "0000473",
  "HBALANCEKIT-N01": "0000474",
  "SDEFENDER-N01": "0000498",
  "GACTIVATOR-N01": "0000313",
}

/*
 * export const bundleToSku = {
 *   "BBLOCKER": "BBLOCKER_N01",
 *   "RPURIFIER" : "RPURIFIER_N01",
 *   "SRELIEVER": "SRELIEVER_N01",
 *   "SDEFENDER": "SDEFENDER_N01",
 *   "GACTIVATOR": "GACTIVATOR_N01",
 *   "MGACTIVATOR": "MGACTIVATOR_N01"
 * }
 */

// let`s populate segment for custom option reporting data that can't inherit from parent
const segmentMap = {
  "One Time": {
    billing_interval: null,
    shipping_interval: null,
    subscription_type: null,
    purchase_type: "Single Purchase",
  },
  "Every 30 days": {
    billing_interval: "1 month",
    shipping_interval: "1 month",
    subscription_type: "Monthly",
    purchase_type: "Growthplan 1",
  },
  "Every 90 days": {
    billing_interval: "3 month",
    shipping_interval: "3 month",
    subscription_type: "3 Month",
  },
  "Every 180 days": {
    billing_interval: "6 month",
    shipping_interval: "6 month",
    subscription_type: "6 Month",
  },
}

/**
 * custom option either has a modifier (ex. -9) on the option, or 0
 * if there is a modifier calculate custom option price
 * otherwise use parent price
 * @param productPrice (parent price from magento gql)
 * @param customOptionPriceModifier (modifier from custom option ex.) -9)
 * @returns productPrice
 */
const calculateOptionPrice = (productPrice, customOptionPriceModifier) => {
  if (customOptionPriceModifier && customOptionPriceModifier !== 0) {
    return productPrice - Math.abs(customOptionPriceModifier)
  }
  return productPrice
}

/**
 * generate reporting attributes off of option title
 * @param product (complete w/ attribute from magento gql)
 * @returns productWithAttributes
 */
const generateReportingAttributes = (product) => {
  let productWithAttributes = { ...product }
  let pt = productWithAttributes?.purchase_type
  if (segmentMap[productWithAttributes?.title]) {
    productWithAttributes = {
      ...productWithAttributes,
      ...segmentMap[productWithAttributes.title],
    }
  }
  // prevent HAIRGROWTHKIT from overwriting in 30days
  if (pt === HAIRGROWTHKIT) {
    productWithAttributes.purchase_type = pt
  }

  return productWithAttributes
}

/**
 * iterate options in order from data file, combine custom option magento data with ui data
 * @param products (complete w/ attribute from magento gql)
 * @param options (atc options display data from our data file)
 * @returns combinedDataOptions
 */
const generateFullProductOptions = (products, options) => {
  if (!options?.[0]?.ui_data) {
    //console.log("products", products)
    return options.map((option) => {
      const productData = products.find(
        (product) =>
          product.sku === `${option.sku}-S01` ||
          product.sku === `${option.sku}-N01` ||
          product.sku === option.sku
      )

      if (productData) {
        return { ...generateReportingAttributes(productData), ui_data: option }
      } else {
        console.error("productData not found for sku ", option.sku)
        return { ui_data: option }
      }
    })
  }
  return options
}

/**
 * parse magento custom options, create all custom sku objects
 * @param products (complete w/ attribute from magento gql)
 * @param options (atc options display data from our data file)
 * @returns allOptions
 */
const getFullProductsFromSubscriptionOptions = (products) => {
  const allOptions = []
  products.forEach((product) => {
    if (product) {
      const purchaseOptions =
        product.options &&
        product.options.find &&
        product.options.find((opt) => opt.title === "Subscription")?.dropDown
      for (const i in purchaseOptions) {
        const opt = purchaseOptions[i]
        const fullSku = `${product.sku}-${opt.sku || "N"}01`
        const optPrice = parseInt(
          calculateOptionPrice(
            product.price_range.minimum_price.regular_price.value,
            opt.price
          )
        )
        opt.sku = fullSku
        opt.shortSku = product.sku
        opt.price = optPrice
        delete opt.options
        delete opt.price_type
        allOptions.push({ ...product, ...opt })
      }
    } else {
      console.error("product in null!")
    }
  })
  return allOptions
}

/**
 * special logic for multiple products on same page
 * take products from magento and data from json file and combine options then return full modified data
 * @param _products (complete w/ attribute from magento gql)
 * @param data (all display data from our data file)
 * @returns fullProductData - meshed data from magento + data file
 */
export const meshProductDuoDataV2 = (_products, data) => {
  const products = JSON.parse(JSON.stringify(_products))
  const allProductOptions = getFullProductsFromSubscriptionOptions(products)
  const atcAreaData = data.atcAreaData || data
  const upsellOptions = data.upsellOptions || []
  for (let k in atcAreaData.products) {
    atcAreaData.products[k].options = generateFullProductOptions(
      allProductOptions,
      atcAreaData.products[k].options
    )
  }
  upsellOptions.forEach((el, idx) => {
    upsellOptions[idx] = {
      ...products.find((prod) => el.sku.replace("-N01", "") === prod.sku),
      ...el,
    }
    upsellOptions[idx].old_price =
      upsellOptions[idx].old_price ||
      upsellOptions[idx].price_range?.minimum_price?.regular_price?.value
  })
  if (atcAreaData.gaArea) {
    atcAreaData.gaArea = {
      ...products.find((el) => el.sku === "GACTIVATOR"),
      ...atcAreaData.gaArea,
    }
  }
  if (atcAreaData.gaAreaMen) {
    atcAreaData.gaAreaMen = {
      ...products.find((el) => el.sku === "MGACTIVATOR"),
      ...atcAreaData.gaAreaMen,
    }
  }
  if (atcAreaData.kitArea) {
    atcAreaData.kitArea = {
      ...products.find((el) => el.sku === "HBALANCEKIT"),
      ...atcAreaData.kitArea,
    }
    atcAreaData.kitArea.price =
      atcAreaData.kitArea.price_range?.minimum_price?.regular_price?.value
  }
  return { ...data, allProductOptions }
}

/**
 * take products from magento and data from json file and combine options then return full modified data
 * @param products (complete w/ attribute from magento gql)
 * @param data (all display data from our data file)
 * @returns fullProductData - meshed data from magento + data file
 */
export const meshProductDataV2 = (products, data) => {
  const allProductOptions = getFullProductsFromSubscriptionOptions(
    products,
    data.atcAreaData.options
  )
  const meshedProductOptions = generateFullProductOptions(
    allProductOptions,
    data.atcAreaData.options
  )
  const fullProductData = data
  // add gaAreaMen SINGLE option for menatc-single-option
  if (data.gaAreaMen && data.gaAreaMen.options) {
    data.gaAreaMen.options[0] = {
      ...products.find((el) => el.sku === "MGACTIVATOR"),
      ...data.gaAreaMen.options[0],
    }
    data.gaAreaMen.options[0].price =
      data.gaAreaMen.options[0].price || data.gaAreaMen.options[0].old_price
  }
  // combine full product data with formatted options with combine ui and magento data
  fullProductData.atcAreaData.options = meshedProductOptions
  // console.log("fullProductData ", fullProductData)
  return fullProductData
}

export const meshProductDataRadio = (products, data) => {
  const allProductOptions = getFullProductsFromSubscriptionOptions(
    products,
    data.atcAreaData.options
  )
  const meshedProductOptions = generateFullProductOptions(
    allProductOptions,
    data.atcAreaData.options
  )
  const meshedRadioProductOptions = generateFullProductOptions(
    allProductOptions,
    data.radioArea.options
  )
  const fullProductData = data
  // combine full product data with formatted options with combine ui and magento data
  fullProductData.atcAreaData.options = meshedProductOptions
  fullProductData.radioArea.options = meshedRadioProductOptions
  // console.log("fullProductData ", fullProductData)
  return fullProductData
}

/**
 **
 *  this is a scalp LP/PDP mesher
 * @param products (from gql)
 * @param attributes (from gql)
 * @param optionsObj (from our cms file, will be merged with productWithAttributes)
 * @returns schemaProductData
 */
export const meshMagentoAndCmsDataV2 = (products, attributes, optionsObj) => {
  if (!optionsObj.items) {
    return null
  }
  optionsObj.items = optionsObj.items.map((el) => {
    return skuToRepack[el.sku] ? { ...el, ...{ sku: skuToRepack[el.sku] } } : el
  })
  const skus = optionsObj.items.map((option) => option.sku)
  const productsWithAttributes = matchAttributeData(
    createProductMap(skus, products),
    attributes
  )
  const schemaProduct = skus[0]
  const schemaProductData = { ...productsWithAttributes[schemaProduct] }

  optionsObj.items.forEach((option) => {
    if (option && option.sku) {
      const productWithAttributes = productsWithAttributes[option.sku]
      meshAttributes(option, productWithAttributes)
      if (typeof option.savings === "number" && option.old_price) {
        option.new_price = option.old_price - option.savings
      }
    }
  })
  // console.warn(optionsObj)

  schemaProductData.price = optionsObj.items[0]
    ? optionsObj.items[0].new_price
    : 0
  delete schemaProductData.old_price
  delete schemaProductData.new_price
  delete schemaProductData.price_range
  delete schemaProductData.image

  return schemaProductData
}

/**
 **
 *  this is a scalp builder LP productlist mesher
 * @param products (from gql)
 * @param attributes (from gql)
 * @param options (from our cms file, will be merged with productWithAttributes)
 * @returns schemaProductData
 */
export const meshMagentoAndCmsDataProductList = (
  products,
  attributes,
  options
) => {
  const optionsObj = {
    items: [
      {
        sku: options.option1_sku,
        title: options.option1_title.text,
        description: options.option1_description.text,
        savings: options.option1_savings,
        display_price: options.option1_display_price,
        code: options.option1_code,
      },
      {
        sku: options.option2_sku,
        title: options.option2_title.text,
        description: options.option2_description.text,
        savings: options.option2_savings,
        display_price: options.option2_display_price,
        code: options.option2_code,
      },
      {
        sku: options.option3_sku,
        title: options.option3_title.text,
        description: options.option3_description.text,
        savings: options.option3_savings,
        display_price: options.option3_display_price,
        code: options.option3_code,
      },
    ],
  }

  if (!optionsObj.items) {
    return null
  }

  const skus = optionsObj.items.map((option) => option.sku)
  const productsWithAttributes = matchAttributeData(
    createProductMap(skus, products),
    attributes
  )

  const productOptions = []
  optionsObj.items.forEach((option) => {
    if (option && option.sku) {
      const productWithAttributes = productsWithAttributes[option.sku]
      if (productWithAttributes) {
        meshAttributes(option, productWithAttributes)
      }
    }
    productOptions.push(option)
  })

  return productOptions
}

/**
 **
 * meshScalpBundle
 * NT2-14802 New SKUs that we will be using for Bundle product (CAREKIT)
 * @param products
 * @param data
 * @return {*}
 */

export const meshScalpBundle = (products, data) => {
  for (let sku in data) {
    const match = products.find((el) => el.sku === sku)
    if (match) {
      match.variant = match.variant || "Single Purchase"
      match.ui_data = data[sku]
      //match.ui_data.bundle_sku = bundleToSku[sku]
      match.ui_data.bundle_sku = sku + "_N01"
      match.ui_data.image = PRODUCT_IMAGES[sku]?.label2
    } else {
      console.error(`meshScalpBundle->match not found for ${sku}`)
    }
  }
}

/**
 * merges pageContext with local data, local data should be structured like the following:
 * {
 *     Mens: {
 *         options: [{sku: ''}],
 *         ...
 *     },
 *     ...
 * }
 * @param {{}} data
 * @param {{}} serverProducts
 */
export const mergeServerProducts = (data, serverProducts) => {
  serverProducts.products.map((product) => {
    Object.entries(data.products).map(([key, value]) => {
      value.options.map((option, id) => {
        //console.log('option', option)
        if (option.sku === product.sku) {
          if (!product?.old_price) {
            product.old_price = option.old_price
          }
          data.products[key].options[id] = { ...option, ...product }
        }
      })
    })
  })
}

/**
 * merges pageContext with local data, local data should be structured like the following:
 * [
 *     {
 *         name: 'Men',
 *         options: [{sku: ''}],
 *         ...
 *     },
 *     ...
 * ]
 * @param {{}} data
 * @param {{}} serverProducts
 */
export const mergeServerProductsArray = (data, serverProducts) => {
  serverProducts.products.map((product) => {
    data.products.map((item) => {
      item.options.map((option, id) => {
        if (option.sku === product.sku) {
          if (!product?.old_price) {
            product.old_price = option.old_price
          }
          item.options[id] = { ...option, ...product }
        }
      })
    })
  })
}

/**
 * take products from magento and data from json file and combine options then return full modified data
 * @param products (complete w/ attribute from magento gql)
 * @param data (all display data from our data file)
 */
export const meshProductData = (products, data) => {
  data.forEach((option) => {
    const productWithAttributes = products.find((p) => p.sku === option.sku)
    meshAttributes(option, productWithAttributes)
  })
}

/**
 * meshAttributes
 * @param option (from our data)
 * @param productWithAttributes (from magento gql)
 */

const meshAttributes = (option, productWithAttributes) => {
  if (productWithAttributes && !option.mapped) {
    //console.warn(productWithAttributes)
    const purchaseOptions = productWithAttributes?.options?.find(
      (opt) => opt.title === "Subscription"
    )?.dropDown

    option.billing_interval = productWithAttributes?.billing_interval
    option.product_category = productWithAttributes?.product_category
    option.core_category = productWithAttributes?.product_core_category
    option.product_gender = productWithAttributes?.product_gender
    option.product_gender_new = productWithAttributes?.product_gender_new
    option.purchase_type = productWithAttributes?.purchase_type
    option.shipping_interval = productWithAttributes?.shipping_interval
    option.subscription_type = productWithAttributes?.subscription_type
    option.name_magento = productWithAttributes?.name
    option.old_price = productWithAttributes?.old_price
    option.new_price =
      productWithAttributes?.price_range?.minimum_price?.regular_price?.value
    option.is_subscription = option.subscription_type ? 1 : 0
    option.mapped = true
    // if Core Growthplan - use Sub sku and Sub price
    if (
      productWithAttributes.product_category === "Growthplan" &&
      purchaseOptions &&
      purchaseOptions[1]
    ) {
      option.sku += "-S01"
      option.new_price = parseInt(
        calculateOptionPrice(option.new_price, purchaseOptions[1].price)
      )
    } else {
      //if Scalp product - single purchase
      option.sku += "-N01"
    }
  }
}
