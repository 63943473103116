import React, { useEffect } from "react"
import { useQuery } from "react-apollo"
import { getRecaptchaKey } from "../../queries/customerpayments"
import { USE_EVIDON } from "../../utils/general"

const ExternalScripts = ({
  includeAcceptjs,
  includeRecaptchajs,
  includeRetentionjs,
  includeMapjs,
}) => {
  const { data } = useQuery(getRecaptchaKey, { skip: !includeRecaptchajs })

  useEffect(() => {
    if (includeRecaptchajs && data && data?.nutrafolRecaptchaV3?.enabled) {
      let container = document.getElementById("extscriptcontainer")
      let recaptchascript = document.createElement("script")
      recaptchascript.id = "grecaptchaV3"
      recaptchascript.type = "text/javascript"
      recaptchascript.src = `https://www.google.com/recaptcha/api.js?render=${data.nutrafolRecaptchaV3.public_key}`
      container.appendChild(recaptchascript)
    }
  }, [includeRecaptchajs, data])

  useEffect(() => {
    let container = document.getElementById("extscriptcontainer")
    if (includeAcceptjs && !window.acceptscript) {
      let acceptscript = document.createElement("script")
      acceptscript.type = "text/javascript"
      acceptscript.id = "acceptscript"
      acceptscript.src = `${process.env.GATSBY_ACCEPT_JS_URL}`
      container.appendChild(acceptscript)
    }
    if (includeMapjs && !window.mapsscript) {
      let mapsscript = document.createElement("script")
      mapsscript.innerHTML =
        "window.initMap=()=>{console.info('Google Maps loaded')}"
      container.appendChild(mapsscript)
      mapsscript = document.createElement("script")
      mapsscript.type = "text/javascript"
      mapsscript.id = "mapsscript"
      mapsscript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAP_KEY}&libraries=places&language=en&region=US&callback=initMap`
      container.appendChild(mapsscript)
    }
    if (includeRetentionjs && !window.ProsperStack) {
      let container = document.getElementById("extscriptcontainer")
      let prosperstackscript = document.createElement("script")
      prosperstackscript.type = "text/javascript"
      prosperstackscript.id = "prosperstackscript"
      prosperstackscript.src =
        "https://cdn.prosperstack.com/flow/latest/flow.min.js"
      container.appendChild(prosperstackscript)
    }
    if (!window.adaNutrafol) {
      let container = document.getElementById("extscriptcontainer")
      let adascript = document.createElement("script")
      adascript.async = true
      adascript.type = "text/javascript"
      adascript.id = "prosperstackscript"
      adascript.src = "/gatsby-assets/js/ada.js"
      container.appendChild(adascript)
      window.adaNutrafol = true
    }
    if (USE_EVIDON && !window.evidonlauncher) {
      let evidonlauncher = document.createElement("script")
      evidonlauncher.type = "text/javascript"
      evidonlauncher.id = "evidonlauncher"
      evidonlauncher.src = "/gatsby-assets/js/evidon.js?04102024"
      container.appendChild(evidonlauncher)
    }
    return () => {}
  })

  return <div id="extscriptcontainer" />
}

export default ExternalScripts
