export const SEGMENT_NAMES = {
  PAY_IT_FORWARD: "pay_it_forward",
  PICK_YOUR_BOOSTER: "pick_your_booster",
  FREE_GIFT: "free_gift",
  UPGRADE_BOOSTER: "upgrade_booster",
  REFER_A_FRIEND: "refer_a_friend",
}

//NT2-20279 Loyalty | Global Ribbon for myNutrafol Customers
export const SEGMENT_PRIORITY = [
  SEGMENT_NAMES.PAY_IT_FORWARD,
  SEGMENT_NAMES.PICK_YOUR_BOOSTER,
  SEGMENT_NAMES.UPGRADE_BOOSTER,
  SEGMENT_NAMES.FREE_GIFT,
]
