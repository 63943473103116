module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"noHash":true,"noQueryString":true,"siteUrl":"https://nutrafol.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Dinamo Light","Dinamo Regular","Dinamo Medium","Dinamo Semi Mono","Dinamo Bold","Gotham Black Condensed","Grilli Book","Grilli Book Italic"],"urls":["/gatsby-assets/fonts/fonts.css?04-04-2024"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"nutrafol","short_name":"nutrafol","start_url":"/","background_color":"#FFFFFF","theme_color":"#000000","display":"browser","icon":"src/assets/nutrafol_favicon.png","icons":[{"src":"/gatsby-general/nutrafol_favicon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/gatsby-general/nutrafol_favicon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/gatsby-general/nutrafol_favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/gatsby-general/nutrafol_favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/gatsby-general/nutrafol_favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/gatsby-general/nutrafol_favicon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/gatsby-general/nutrafol_favicon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/gatsby-general/nutrafol_favicon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9b93247ec6f08c44c1686e4904817719"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/deploy/build/src/components/layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"27eHFUsVp3BIlMHftXL7kHsjFRXAyqLW","devKey":"27eHFUsVp3BIlMHftXL7kHsjFRXAyqLW","trackPage":false,"trackPageDelay":3000,"host":"https://sc.nutrafol.com","delayLoad":false,"delayLoadTime":1500},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
