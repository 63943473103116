import React from "react"
import { navigate } from "gatsby"
import styled from "@emotion/styled"
import { Box } from "@rebass/grid/emotion"

import { TextElementMen } from "@nutrafol/nutrafol-ui-kit/dist/TextElementMen"
import ArrowIcon from "../../../../assets/icons/wild/arrow-right-green.svg"
import { data } from "../../../../data/men/men-concierge"

const NavigationShopBannerBox = styled(Box)`
  width: 25%;
  min-height: 267px;
  cursor: pointer;
  padding: 8px 8px 8px 0;
  margin-top: 8px;
  border-top-right-radius: 8px;
  /* border-bottom-right-radius: 8px; */
  background: #fff;
  box-shadow:
    0px 0px 20px rgba(0, 0, 0, 0.05),
    0px 24px 38px rgba(0, 0, 0, 0.03),
    0px 9px 46px rgba(0, 0, 0, 0.03),
    0px 11px 15px rgba(0, 0, 0, 0.03);

  > div {
    background-image: url("https://images.ctfassets.net/0rbfqd9c4jdo/7mgI9BisAWCilKK0RlxHQR/c493b6e444c3418af68469c8fe8db350/growthplanplus-menu-banner-men.jpg?fl=progressive");
    background-size: cover;
    background-position-x: 70%;
    height: 100%;
    border-radius: 4px;
    position: relative;
    @media (min-width: 1280px) {
      background-position-x: center;
    }
  }

  @media (min-width: 1024px) {
    min-height: 323px;
  }
  @media (min-width: 1280px) {
    // height: 317px;
  }
`

const ButtonWrapperBanner = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
  max-width: 220px;

  .btn-green {
    color: #00857d !important;
    font-weight: bold;
  }

  .paragraph {
    max-width: 184px;
  }

  @media (min-width: 1280px) {
    max-width: 274px;
  }

  button {
    display: flex;
    flex-direction: row;

    svg {
      transform: translate(4px, 3px);
    }
  }
`

const NavigationShopBanner = ({ trackClicked }) => {
  return (
    <NavigationShopBannerBox
      onClick={() => {
        trackClicked(data.touchPoints.label, data.touchPoints.link)
        navigate(data.touchPoints.link)
      }}
    >
      <div>
        <ButtonWrapperBanner>
          <TextElementMen
            element="h4"
            text={data.touchPoints.title}
            className="title--small text-master-base-default show-tablet"
          />
          <TextElementMen
            element="h4"
            text={data.touchPoints.title}
            className="title--medium text-master-base-default show-lg-up"
          />
          <Box mt={[8]} mb={[8]} maxWidth={[131]}>
            <TextElementMen
              element="p"
              text={data.touchPoints.subtitle}
              className="body--small paragraph text-master-base-default"
            />
          </Box>

          <button>
            <TextElementMen
              element="p"
              text={data.touchPoints.label}
              className="body--small btn-green text-master-primary-default"
            />
            <ArrowIcon />
          </button>
        </ButtonWrapperBanner>
      </div>
    </NavigationShopBannerBox>
  )
}

export default NavigationShopBanner
