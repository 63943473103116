import React from "react"

/*
 * AdChoicesWrap is a hidden evidon-notice-link holder, added to footers to avoid errors thrown by evidon scripts.
 * we are using our own adchoiceslink.js
 */
const AdChoicesWrap = () => {

  if(process.env.GATSBY_EVIDON_ID) {
    return (
      <div className={`evidon-notice-wrap hidden`}>
        <span className="evidon-notice-link hidden"></span>
      </div>
    )
  } else {
    return null
  }
}

export default AdChoicesWrap