import React, { useMemo, useContext } from "react"

import { SearchContext } from "./searchcontext"

import { SearchTitle } from "./search.styles"

/**
 * Limit suggested items to max: 5
 *
 * @param {string} query
 * @param {[]} items
 * @param {[]} data
 * @param {Function} onSuggestedClicked
 * @returns {Element}
 * @constructor
 */
const QueryText = ({ query, items, data, onSuggestedClicked }) => {
  const { resultSearchVisible } = useContext(SearchContext)
  const { article, product } = resultSearchVisible

  const suggestedItems = items
    .slice(0, 5)
    .filter((item) => item.query.toLowerCase() !== query.toLowerCase())

  const results = useMemo(
    () =>
      data
        .map((result) => {
          if (result?.hits.length) return result?.hits
        })
        .filter((items) => items),
    [data]
  )

  return (
    <div>
      {article || product || (results?.length && !suggestedItems.length) ? (
        <p className={"search-result-text ml-4 sm:h-[36px] pb-2"}>
          <span>Showing results for</span>
          <span className={"font-dinamoBold text-master-base-black"}>
            &nbsp;"{query}"
          </span>
        </p>
      ) : (
        <>
          {Boolean(suggestedItems.length) && (
            <div className={"flex items-baseline sm:h-[36px] pb-2"}>
              <div
                className={
                  "inline-flex gap-2 sm:gap-3 flex-wrap items-baseline"
                }
              >
                <SearchTitle text={"Suggested:"} />
                {suggestedItems.map((suggested, id) => (
                  <button
                    className={"suggested-hit capitalize"}
                    type="button"
                    onClick={() => onSuggestedClicked(suggested)}
                    key={suggested?.query || id}
                  >
                    {suggested?.query}
                  </button>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default QueryText
