export const notificationActionObject = {
  schedule: {
    actionText: 'Schedule Now',
    actionFunc: () => {},
    actionLink: ''
  },
  learnMore: {
    actionText: 'Learn More',
    actionFunc: () => { console.log("test") },
    actionLink: ''
  },
}