import React, { useState, useEffect } from "react"
import { isMultipleSkuDpa, getPropertiesFromDpa } from "../utils/general"

const defaultState = {
  cartId: null,
}

const CartContext = React.createContext(defaultState)

const CartProvider = (props) => {
  const { children } = props

  const [cartNum, setCartNum] = useState(0)
  const [multipleSkusAdded, setMultipleSkusAdded] = useState(false)
  const [cartData, setCartData] = useState()
  const [hasError, setHasError] = useState(false)
  const [activeElement, setActiveElement] = useState(false)
  const [isLoadingAjaxCart, setIsLoadingAjaxCart] = useState(false)
  const [isCartFetching, setIsCartFetching] = useState(false)
  const [skusInFlight, setSkusInFlight] = useState([])

  const getCartTotalItems = (items) => {
    let totalItems = 0
    items &&
      Object.keys(items).forEach((key) => {
        if (items[key] && items[key].quote && items[key].quote.qty) {
          totalItems += items[key].quote.qty
        }
      })
    setCartNum(totalItems)
  }

  useEffect(() => {
    if (!!cartData && cartData.items) {
      // hack to account for different response formats in ajax endpoint and nutrafol-carts
      getCartTotalItems(cartData.items)
    } else if (cartData && cartData !== "Quote does not exist.") {
      getCartTotalItems(cartData[1])
    }
  }, [cartData])

  const addToCart = (url, successCallback) => {
    setActiveElement(document.activeElement)
    setIsLoadingAjaxCart(false)
    setMultipleSkusAdded(isMultipleSkuDpa(url.split("?")[1]))
    const body = getPropertiesFromDpa(url)

    if (body.skus && body.purchase_source) {
      setSkusInFlight([...skusInFlight, body.skus])
      setIsLoadingAjaxCart(true)
      setHasError(false)
      fetch(`${process.env.GATSBY_MAGENTO_URL}rest/V1/repack/add_to_cart/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((resJson) => {
          setCartData(resJson)
          setHasError(
            resJson && resJson.indexOf && resJson.indexOf("error") > -1
          )
          setIsLoadingAjaxCart(false)
          setSkusInFlight([])
          if (successCallback) {
            successCallback()
          }
        })
        .catch((error) => {
          setSkusInFlight([])
          setIsLoadingAjaxCart(false)
          setHasError(true)
          console.error("Error:", error)
        })
    } else {
      console.warn("DPA link is incorrect.")
    }
  }

  return (
    <CartContext.Provider
      value={{
        isLoadingAjaxCart,
        setIsCartFetching,
        isCartFetching,
        cartData,
        cartNum,
        multipleSkusAdded,
        hasError,
        activeElement,
        skusInFlight,
        setMultipleSkusAdded: (bool) => setMultipleSkusAdded(bool),
        setCartNum: (num) => setCartNum(num),
        setCart: (cartPayload) => setCartData(cartPayload),
        addItems: (url, successCallback) => addToCart(url, successCallback),
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartContext

export { CartProvider }
