import React from "react"

import { Button } from "@nutrafol/nutrafol-ui-kit/dist/Button"

import { 
  ModalHeading,
  TextMedium
} from "../account/styled"

const GenericModalContent = ({message, closeButtonText, hideModal}) => {
  return (
    <>
      <ModalHeading>{message.title}</ModalHeading>
      <TextMedium>{message.content}</TextMedium>
      <Button 
        className="mt-6"
        onClick={hideModal} 
        label={closeButtonText} 
        primary
        type="submit"
      />
    </>
  )
}

export default GenericModalContent