import React from "react"
import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"

import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

import ActionLink from "../account/actionlink"

import InfoIconGreen from "../../assets/icons/info-icon-green.svg"
import CheckmarkIcon from "../../assets/icons/wild/checkmark-icon.svg"
import InfoIconOrange from "../../assets/icons/info-icon-orange.svg"
import ErrorTriangle from "../../assets/icons/wild/account-error-triangle.svg"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"

const IconWrap = styled(Box)`
  &.info {
    svg {
      transform: translateY(4px);
    }
  }
`
const ContentBox = styled(Flex)`
  width: 100%;
  align-items: center;
  // do we need logic here?
  &.has-action {
    //width: 80%;
  }
`

const NotificationMessages = (props) => {

  const { message, actionText, actionLink, actionFunc, columnSmall, mini } = props

  const type = message.type ? message.type : 'error'
  const msg = message.text ? message.text : message.message ? message.message : ''
  
  const getIcon =  ()=> {
    if (type === "warning") {
      return  <InfoIconOrange />
    }
    if (type === "success") {
      return  <InfoIconGreen />
    }
    if (type === "info") {
      return  <CheckmarkIcon />
    }
    return <ErrorTriangle />
    
  }

  const SessionMessage = styled(Box)`
    color: ${theme.wild.color.master.base.black};
    font-size: 14px;
    line-height: 26px;
    font-weight: 300;
    letter-spacing: 0;
    padding: ${mini ? "0" : "0 15px 0 0"};
    &.error {
      //color: #e02b27;
    }
    &.success {
      //color: #22202F;
    }
    &.info {
     // color: #22202F;
    }
    .c-bold-date {
      font-weight: bold;
    }
    > span {
      font-family: ${theme.wild.font.dinamo.regular};
      font-weight: ${theme.weight.book};
    }
  `
  // animate the height of this
  const MessageWrapper = styled(Flex)`
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
  `

  const hasAction =  actionText || actionLink || actionFunc

  return (
    <>
      {message &&
        <MessageWrapper flexWrap="wrap" mx={-1} flex={1} className={``}>

          <ContentBox px={1} className={ hasAction ? 'has-action' : ''} >
            <IconWrap px={1} className={type} width={[40]} minHeight={[20]}>
              {getIcon()}
            </IconWrap>
            <Flex flex={1} flexDirection={['column','column','column','row']}>
              <Flex flexWrap="wrap" width={[1,1,1,.8]}>
                <SessionMessage className={type}>
                  <TextElement element="p" text={msg} className="body--large" />
                  {/*<TextElement element="p" text={msg} className="body--xlarge show-md-up" />*/}
                </SessionMessage>
              </Flex>
              <Flex width={[1,1,1,.2]} pr={[0,0,16,16]} alignItems={`center`} justifyContent={[`flex-start`,`flex-start`,`flex-start`,`flex-end`]}>
                {
                  (hasAction)
                    ?
                    <ActionLink
                      actionText={actionText}
                      actionLink={actionLink}
                      actionFunc={actionFunc}
                      mobile={true}
                      columnSmall={columnSmall}
                    />
                    :
                    null
                }
              </Flex>
            </Flex>

          </ContentBox>
        </MessageWrapper>
      }
    </>
  )

}

export default NotificationMessages