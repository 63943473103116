import { SearchImageText, SearchItemImageContainer } from "./search.styles"

import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"

/**
 *
 * @param {{}} data
 * @param {Function} onClick
 * @returns {JSX.Element}
 * @constructor
 */
const ResultCard = ({ data, isSearchResult = false }) => {
  return (
    <SearchItemImageContainer
      className={"rounded-md h-full"}
      isSearchResult={isSearchResult}
    >
      <img
        src={`${data.searchImage.url}?fm=jpg&fl=progressive`}
        alt={""}
        className={"rounded-t-md"}
      />
      <SearchImageText>
        <TextElement
          className={
            "body--regular-b text-master-primary-secondary text-center"
          }
          text={data.title}
        />
        <TextElement
          className={"body--small text-master-base-black text-center"}
          text={data.subTitle}
        />
      </SearchImageText>
    </SearchItemImageContainer>
  )
}

export default ResultCard
