import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

export const HEADER_HEIGHT_MOBILE = 96
export const HEADER_HEIGHT_DESKTOP = 72
export const tabletFold = 1024

export const AboveHeaderWrap = styled(Flex)`
  position: relative;
`

export const SiteSwitchBox = styled(Box)`
  background: #fff;
  display: none;
  @media (min-width: 768px) {
    display: block;
    background: transparent;
  }
`

export const HeaderWrap = styled.header`
  background: transparent;

  .toggler-gradient {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 4.93%
    );

    a {
      width: 100%;
      text-align: center;
      margin: auto;
      display: block;

      svg {
        display: inline;
        transform: translate(0, -1px);
      }
    }
  }

  .right-wrap-account {
    @media (min-width: 768px) {
      padding-right: 10px;
    }
  }

  &.nav-open {
    .wrap {
      position: relative;

      .toggle-nav {
        margin-top: 0;
        margin-left: 0;

        span {
          transform: rotate(45deg) !important;
          top: 1.5rem;
          width: 20px;

          &:before {
            background-color: transparent;
          }

          &:after {
            top: 0;
            transform: rotate(-90deg) !important;
          }
        }
      }
    }

    &.second-level-active {
      .back-wrapper {
        display: inline-block;
      }
    }
  }

  &[data-mousedown] {
    *:focus {
      outline: none;
    }
  }

  @media (min-width: 768px) {
    // height: ${(props) => (props.isLoggedIn ? "108px" : "72px")};
    /* height: 72px; */
    height: auto;
  }
  @media (min-width: 1024px) {
    // height: ${(props) => (props.isLoggedIn ? "135px" : "80px")};
    /* height: 80px; */
  }
`

export const HeaderContainer = styled.div`
  position: relative;
  padding: 0 !important;
`

export const ContentsContainer = styled.div`
  > div:not(.ribbon-container, .mobile-nav-flex) {
    background: #fff;
  }
  @media (min-width: 768px) {
    > div:not(.ribbon-container, .mobile-nav-flex) {
      padding-left: 2px;
      border-radius: 8px;
      box-shadow:
        0px 11px 15px 0px rgba(0, 87, 82, 0.08),
        0px 9px 46px 0px rgba(0, 87, 82, 0.06),
        0px 24px 38px 0px rgba(0, 87, 82, 0.06) !important;
    }
  }
  @media (min-width: ${tabletFold}px) {
    font-size: 0;
    > div:not(.ribbon-container, .mobile-nav-flex) {
      padding-left: 6px;
    }
    .right-wrap {
      padding-right: 8px;
    }
    .toggler-wrap {
      order: 1;
      width: 104px;
      padding: 0 0 0 2px;
    }

    .left-wrap {
      order: 3;
      width: calc(100% - 444px);
      align-self: center;
      padding-left: 16px;
    }

    .center-wrap {
      order: 2;
      width: 125px;
      align-self: center;
    }

    .right-wrap {
      order: 4;
      width: 215px;
      align-self: center;
    }
  }
  @media (min-width: 1024px) {
    .left-wrap {
      order: 2;
      width: calc(50% - 160px);
      padding-left: 0;
    }

    .center-wrap {
      order: 3;
      width: 150px;
    }

    .right-wrap {
      order: 4;
      width: calc(50% - 85px);
    }
  }
  @media (min-width: 1280px) {
    .right-wrap {
      width: calc(50% - 93px);
      //padding-right: 24px;
    }
  }
`

export const BelowFoldContainer = styled.div`
  height: inherit;
  border-radius: 4px;

  @media (min-width: 768px) {
    background: #fff;
    padding: 8px;
    border-radius: 8px;
  }
  @media (min-width: 1024px) {
    padding: 0 8px;
  }
`

export const MobileAlt = styled.button`
  display: contents;
  text-align: center;
  height: 56px;

  span.textelement.body--large.text-master-base-default {
    display: block !important;
    width: calc(100% - 16px);
    line-height: 56px !important;
    height: 56px;
    color: #fff !important;
    background: #008078;
    font-size: 18px !important;
    border-radius: 4px;
    margin: 8px auto;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

export const NavVar1 = styled(Flex)`
  background-color: #f8f8f5;
  height: 44px;
  border-bottom: 1px #c8d2d1 solid;

  button.textLink {
    font-size: 12px !important;
  }

  .takequiz {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0 16px;
    background: ${theme.wild.color.nova.transparency.black[60]};

    .textLink {
      color: ${theme.wild.color.nova.base.white} !important;
      font-weight: 700 !important;
      background: none;
    }
  }
`
