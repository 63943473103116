import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"

import LogoSVG from "../../../../assets/icons/wild/nutrafol-logo-mens.svg"

const Logo = (props) => {
  const LogoArea = styled.div`
    padding: 18px 0;
    ${queries.large`
      padding: 24px 0;
    `}
  `

  const LogoImageHolder = styled.span`
    @media (max-width: 767px) {
    }
    svg {
      margin: 0 auto;
      width: 163px;
      transform: translate(-10px);
      @media (min-width: 345px) {
        width: 183px;
      }
      @media (min-width: 420px) {
        transform: none;
      }
      @media (min-width: 768px) {
        width: 125px;
      }
      @media (min-width: 1024px) {
        width: 146px;
      }
    }
  `

  return (
    <LogoArea className="logo-wrap" onClick={props.handleNavLogoExitClick}>
      <Link to="/men/">
        <LogoImageHolder>
          <LogoSVG alt="home" className={`LogoSVGMen`} />
        </LogoImageHolder>
      </Link>
    </LogoArea>
  )
}

export default Logo
