export const pathNameFormatter = (path) => {
  // console.log("path ", path)
  if (path) {
    const pageMap = {
      "/": "HOME",
      "/products/": "PLP",
      "/nutrafol-core-for-men/": "PDP",
      "/nutrafol-core-for-women/": "PDP",
      "/women-balance/": "PDP"
    }
    if (pageMap[path]) {
      // console.log(pageMap[path])
      return pageMap[path]
    } else {
      // console.log(path.replace(/[\/,-]/g, "").toUpperCase())
      return path.replace(/[\/,-]/g, "").toUpperCase()
    }
  }
  return "MARKETING"
}