import React, { useEffect, useState } from "react"
import { Flex } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"
import DownNav from "../../../../assets/icons/wild/wild-caret.svg"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
//Product Dropdown Viewed, Product Selected, Product Added
const AltContentsContainer = styled(Flex)`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }

`

const ButtonsWrap = styled(Flex)`
  margin-left: auto;

  button.c-btn {
    width: 94px;
    height: 56px;
    padding-left: 0;
    padding-right: 0;
  }
`
const ButtonsHolder = styled.div`
  position: relative;
  width: 340px;
  height: 56px;
  background: ${theme.color.beige};
  border-radius: 4px;
  margin: 0 8px 0 0;
  padding: 0;
  @media (min-width: 1024px) {
    width: 382px;
  }
  
`

const ButtonsFloat = styled.div`
  position: absolute;
  cursor: pointer;
  width: 340px;
  @media (min-width: 1024px) {
    width: 382px;
  }
  height: auto;
  top: 0;
  padding: 8px;
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 24px 38px rgba(0, 0, 0, 0.03), 0 9px 46px rgba(0, 0, 0, 0.03), 0 11px 15px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  
`

const OptionWrap = styled.button`
  width: 340px;
  @media (min-width: 1024px) {
    width: 382px;
  }
  height: 56px;
  line-height: 56px;
  background: ${theme.color.white};
  border-radius: 4px;
  border: 1px #c8c2d1 solid;

  span, svg {
    display: inline-block;
  }
  span {
    line-height: 28px !important;
    //border: 1px red dotted;
  }
   .c-price {
     width: 44px;
   }

  .c-title {
    width: 135px;
    padding: 0 0 0 8px;
    @media (min-width: 1024px) {
      width: 150px;
      //padding: 0 0 0 16px;
    }
    //text-align: left;

  }
  
  .c-savings {
    color: ${theme.wild.color.master.base.black};
    background: ${theme.color.yellow};
    border-radius: 4px;
    padding: 2px 2px;
    text-align: center;
    width: 64px;
    height: 30px;
    transform: translate(0, -1px);
    @media (min-width: 1024px) {
      width: 81px;
    }
    &.no-savings {
      visibility: hidden;
    }
  }

  .body--small.c-strike {
    color: ${theme.wild.color.master.base.alt.alt};
    text-decoration: line-through;
    line-height: 18px;
    font-size: 12px;
    width: 32px;
  }
  
  .plus-icon {
    width: 30px;
    padding: 0;
    transform: translate(12px) rotate(180deg);
  }


  &.normal {
    border: none;
    background: ${theme.wild.color.master.base.secondary};
  }


  &.float {
    height: 64px;
    line-height: 64px;
    width: 324px;
    @media (min-width: 1024px) {
      width: 366px;
    }
    margin: 8px 0 0 0;
    .plus-icon {
      visibility: hidden;
    }
    &:hover, &:focus, &:focus-visible {
      border: 1px ${theme.wild.color.master.primary.alt} solid;
      background: ${theme.wild.color.master.primary.alt};
    }
  }
`

const Chooser = styled.div`
  width: 324px;
  @media (min-width: 1024px) {
    width: 366px;
  }
  height: 48px;
  
  padding: 12px 0 0 0;

  span, svg {
    display: inline-block;
  }
  .c-choose {
    width: 270px;
    padding: 0 0 0 16px;
    @media (min-width: 1024px) {
      width: 309px;
      padding: 0 0 0 20px;
    }
    text-align: left;

  }
  .plus-icon {
    width: 45px;
    padding: 0;
    transform: translate(14px, -1px);
    @media (min-width: 1024px) {
      transform: translate(16px, -1px);
    }
  }
`

const atcText = 'Select'

const HeaderAtc = (props) => {

  const {atcData, activeOption, setActiveOption}  = props.state
  const [isFloat, setIsFloat] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if (typeof document !== "undefined") {
      const handleClick = () => {
        if(isFloat) {
          setIsFloat(false)
        }
      }
      const handleKey = function(evt) {
        evt = evt || window.event
        var isEscape = evt && (evt.key === "Escape" || evt.key === "Esc" || evt.keyCode === 27)
        if (isEscape) {
          if(isFloat) {
            setIsFloat(false)

            const header = document.querySelector("header")
            const main = document.querySelector("main")
            const footer = document.querySelector("footer")

            header && header.setAttribute("data-mousedown", "1")
            main && main.setAttribute("data-mousedown", "1")
            footer && footer.setAttribute("data-mousedown", "1")
          }
        }
      }
      document.addEventListener("click", handleClick)
      document.addEventListener("keydown", handleKey)
      return () => {
        document.removeEventListener("click", handleClick)
        document.removeEventListener("keydown", handleKey)
      }
    }
  }, [isFloat])

  if(atcData===null){
    return null
  }

  const options = atcData.options

  const atcClick = () => {
    if(isSubmitted){
      return
    }
    if(!isFloat){
      const option = options[activeOption]
      setIsSubmitted(true)
      segmentEvent("Product Added", {
        "text": atcText,
        "name": atcData.title,
        "sku":  option.sku,
        "price": option.new_price,
        "variant": option.title,
        "product_gender": option.product_gender_new,
        "billing_interval": option.billing_interval,
        "core_category": option.core_category,
        "product_category":  option.product_category,
        "purchase_type": option.purchase_type,
        "shipping_interval":  option.shipping_interval,
        "quantity": 1,
        "quiz_reco": false,
        "source": "scalp_pdp",
        "type": "button_stickynav"
      })
      window.location.href = process.env.GATSBY_MAGENTO_URL + 'dpa/add/tocart?skus=' + option.sku + `&purchase_source=scalp_pdp`
    }
  }

  const onSelect = (idx)=>{
    const option = options[idx]
    segmentEvent("Product Selected", {
      "variant": option.title,
      "core_category": option.core_category,
      "name": atcData.title,
      "sku":  option.sku,
      "price": option.new_price,
      "product_gender": option.product_gender_new,
      "type": "dropdown_stickynav"
    })
  }

  const onOpen = ()=> {
    const option = options[activeOption]
    segmentEvent("Product Dropdown Viewed", {
      "image_url": atcData.mainimg,
      "name": atcData.title,
      "product_gender": option.product_gender_new,
      "type": "dropdown_stickynav"
    })
  }

  const renderOption = (idx, type = 'normal')=>{
    const option = options && options[idx] ? options[idx] : null

    if (!option){
      return null
    }

    return <OptionWrap id={`option-${option.id}-float`} className={type} onClick={(e)=>{
      if(type === 'float'){
        setActiveOption(idx)
        onSelect(idx)
      } else {
        e.nativeEvent.stopImmediatePropagation()
        setIsFloat(true)
        onOpen()
      }
    }}>
      <TextElement element="span" text={`$${option.new_price}`} className="body--large-b c-price" />
      <TextElement element="span" aria-label={option.old_price && (option.old_price !== option.new_price) ? `was ${option.old_price} dollars, now discounted` : ``} text={option.old_price && (option.old_price !== option.new_price) ? `$${option.old_price}` : ``} className="body--small c-strike" />
      <TextElement element="span" text={option.option_title} className="body--large-b c-title" />
      <TextElement element="span" text={option.savings} className={`body--regular c-savings ${option.savings ? 'has-savings' : 'no-savings'}`}/>
      <DownNav className="plus-icon"/>
    </OptionWrap>
  }

  const mapper = options && options.map ? options.map((el,idx) => renderOption(idx, 'float')) : null

  return (
    <AltContentsContainer>
      <ButtonsWrap>
        <ButtonsHolder  >
          {renderOption(activeOption)}
          {isFloat &&
          <ButtonsFloat>
            <Chooser>
              <TextElement element="span" text={`Choose an Option`} className="body--large c-choose" />
              <DownNav className="plus-icon"/>
            </Chooser>
            {mapper}
          </ButtonsFloat>
          }
        </ButtonsHolder>
        <ButtonWild
          className={`c-btn ${isFloat || isSubmitted ? 'disabled' : ''}`}
          primary
          label={atcText}
          onClick={atcClick}
        />
      </ButtonsWrap>
    </AltContentsContainer>
  )
}

export default HeaderAtc
