import React, { memo, useContext } from "react"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import { SearchContext } from "./searchcontext"

import { SearchTitle } from "./search.styles"

/**
 *
 * @param {string} title
 * @param {[]} items
 * @param {Function} onClick
 * @param {string} className
 * @param {boolean} isLink
 * @returns {React.JSX.Element}
 * @constructor
 */
const LinkedItems = memo(({ title, items, onClick }) => {
  const { searchState, defaultFAQs } = useContext(SearchContext)

  const links = items?.length ? items : defaultFAQs

  return (
    <div>
      {title && <SearchTitle text={title} />}
      <ul>
        {links.map(
          (item, id) =>
            (item?.question || item?.title) && (
              <li
                className={"mt-2 sm:mt-4"}
                key={item?.id || item?.objectID || id}
              >
                <button
                  onClick={(e) => {
                    const obj = {
                      name: "Search",
                      location: "navigation",
                      state: searchState,
                      text: item?.question || item?.title,
                      type: "Link",
                    }
                    if (item?.path) {
                      obj.destination = item.path
                    }
                    segmentEvent("Search Clicked", obj)
                    onClick(e, item)
                  }}
                  className={"text-link cursor-pointer text-left"}
                >
                  {item?.question || item?.title}
                </button>
              </li>
            )
        )}
      </ul>
    </div>
  )
})

export default LinkedItems
