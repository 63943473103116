import React from "react"
import { Link } from "gatsby"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { TextElementNova } from "@nutrafol/nutrafol-ui-kit/dist/TextElementNova"
import Logo from "../../../../../assets/icons/wild/logo-icon.svg"
import { data } from "../data"
import {
  FooterContainer,
  MainBox,
  LogoWrap,
  StyledLeftSide,
  StyledRightSide,
  StyledLinks,
} from "./index.styled"

import AdChoicesLink from "../../../global/adchoiceslink/adchoiceslink"

const text =
  "© " +
  new Date().getFullYear() +
  "  Nutraceutical Wellness Inc. All Rights Reserved."

const LinkList = ({ list }) => {
  const handleCtaClick = (el) => {
    segmentEvent("Marketing CTA Clicked", {
      destination: el.link.split("?")[0],
      // module_location: "women_footer",
      text: el.title,
      type: "link",
    })
  }

  const linkList = list.map((el, idx) => {
    if (el.link === "adchoices") {
      const cls = process.env.GATSBY_RENDER_EVIDON === "1" ? "block" : "hidden"
      return (
        <div className={`link ${cls}`} key={`${el.link}-${idx}`}>
          <AdChoicesLink />
        </div>
      )
    }
    return (
      <div
        className={`link ${el.title.toLowerCase()}`}
        key={`${el.link}-${idx}`}
      >
        {el.link.indexOf("http") === 0 ? (
          <a
            href={el.link}
            onClick={() => handleCtaClick(el)}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`${el.title} (Opens in new tab)`}
          >
            <TextElementNova
              element="span"
              className="Body-10-Dia-Normal"
              text={el.title}
            />
          </a>
        ) : (
          <Link
            to={el.link}
            onClick={() => handleCtaClick(el)}
            state={el?.state ? el.state : {}}
          >
            <TextElementNova
              element="span"
              className="Body-10-Dia-Normal"
              text={el.title}
            />
          </Link>
        )}
      </div>
    )
  })
  return linkList
}

const NovaFooterCart = () => {
  return (
    <FooterContainer data-mousedown>
      <MainBox>
        <StyledLeftSide>
          <div className="flex">
            <LogoWrap>
              <Logo />
            </LogoWrap>

            <div className="content-wrapper">
              <TextElementNova
                element="p"
                text={text}
                className="Body-10-Dia-Normal"
              />
              <TextElementNova
                element="p"
                text="These statements have not been evaluated by the Food and Drug Administration."
                className="Body-10-Dia-Normal"
              />
              <TextElementNova
                element="p"
                text="These products are not intended to diagnose, treat, cure, or prevent any disease."
                className="Body-10-Dia-Normal"
              />
            </div>
          </div>
        </StyledLeftSide>

        <StyledRightSide>
          <StyledLinks className="col-legal-s">
            <TextElementNova
              element="h3"
              className="Caption-10-Dia-Uppercase section-header"
              text="Legal"
            />

            <LinkList list={data.policy} />
          </StyledLinks>
        </StyledRightSide>
      </MainBox>
    </FooterContainer>
  )
}

export default NovaFooterCart
