import gql from "graphql-tag"

export const requestArticle = gql`
  query ShowArticle($id: String!) {
    ShowArticle(article_id: $id) {
      article {
        body
        title
      }
    }
  }
`
