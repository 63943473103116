import React from "react"
import Section from "../section/section"
import { TextElementNova } from "@nutrafol/nutrafol-ui-kit/dist/TextElementNova"
import LegalDisclaimerWrapper from "./index.styled"

const LegalDisclaimer = ({ data }) => {
  return (
    <LegalDisclaimerWrapper>
      <Section>
        <TextElementNova
          text={data.content}
          element="p"
          className="Caption-10-Dia-Normal"
        />
      </Section>
    </LegalDisclaimerWrapper>
  )
}

export default LegalDisclaimer